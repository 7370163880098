<template>
  <div class="row">
    <login-banner></login-banner>
    <div class="col-md-6 login-wrap-bg">
      <div class="login-wrapper">
        <div class="loginbox">
          <div class="w-100">
            <div class="img-logo">
              <img
                src="@/assets/img/logo/logo_letras.svg"
                class="img-fluid"
                alt="Logo"
              />
              <div class="back-home">
                <router-link to="/home/">Volver a Inicio</router-link>
              </div>
            </div>
            <h1>Inicia sesión en tu cuenta</h1>
            <Form
              @submit="iniciarSesion"
              :validation-schema="schema"
              v-slot="{ errors, isSubmitting }"
            >
              <div class="input-block">
                <span style="color: #685f89; font-size: 15px">
                  Correo electrónico</span
                >
                <div class="form-addons">
                  <Field
                    name="email"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                  <div class="emailshow text-danger" id="email"></div>
                </div>
              </div>
              <div class="input-block">
                <span style="color: #685f89; font-size: 15px">Contraseña</span>
                <div class="pass-group">
                  <Field
                    name="password"
                    :type="showPassword ? 'text' : 'password'"
                    class="form-control pass-input mt-2"
                    :class="{ 'is-invalid': errors.password }"
                  />
                  <span
                    @click="toggleShow"
                    class="toggle-password"
                    :class="{
                      'feather-eye': showPassword,
                      'feather-eye-off': !showPassword,
                    }"
                  ></span>
                  <div class="invalid-feedback">{{ errors.password }}</div>
                  <div class="emailshow text-danger" id="password"></div>
                </div>
              </div>
              <div class="forgot">
                <span
                  ><a class="forgot-link" href="forgot-password"
                    >¿Has olvidado tu contraseña?</a
                  ></span
                >
              </div>
              <div class="d-grid">
                <button
                  type="submit"
                  class="btn btn-info btn-start"
                  :disabled="isSubmitting"
                >
                  Iniciar sesión
                  <span
                    v-if="isSubmitting"
                    class="spinner-border spinner-border-sm"
                  ></span>
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div class="google-bg text-center">
          <p class="mb-0 mt-0">
            ¿Nuevo usuario?<router-link to="register"
              >Crear una cuenta</router-link
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { useAuth } from "@/stores";

export default {
  components: {
    Form,
    Field,
  },
  data() {
    return {
      showPassword: false,
      schema: Yup.object().shape({
        email: Yup.string()
          .required("El correo electrónico es obligatorio")
          .email("Formato de correo inválido"),
        password: Yup.string()
          .min(6, "La contraseña debe tener al menos 6 caracteres")
          .required("La contraseña es obligatoria"),
      }),
      isSubmitting: false,
      loginError: "", // Añadido para mostrar errores específicos de login
    };
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    async iniciarSesion(values) {
      this.isSubmitting = true;
      this.loginError = ""; // Limpia el error al comenzar una nueva sesión

      try {
        await this.schema.validate(values, { abortEarly: false });

        const authStorage = useAuth();
        const loginSuccess = await authStorage.userLogin(values);

        if (loginSuccess) {
          this.$router.push("/home/");
        } else {
          this.loginError =
            "Credenciales incorrectas. Verifica tu correo y contraseña.";
        }
      } catch (validationErrors) {
        console.error("Errores de validación:", validationErrors);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
