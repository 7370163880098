<template>
  <div class="tab-pane fade" id="given_review">
    <!-- Reseña -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>hace 6 meses</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          Stephen ha demostrado una comprensión y compromiso excepcionales en el
          curso "Introducción a la Economía". Tus habilidades analíticas y tu
          capacidad para aplicar teorías económicas a escenarios del mundo real
          han sido impresionantes. Tu participación activa en las discusiones de
          clase también ha contribuido positivamente al ambiente de aprendizaje.
          ¡Sigue así!
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Editar</a>
          <a href="javascript:void(0);">Eliminar</a>
        </div>
      </div>
    </div>
    <!-- /Reseña -->
    <!-- Reseña -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>hace 8 meses</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          Lashawn, he notado tu gran interés en "Historia del Arte Moderno" y
          aprecio tus esfuerzos por entender conceptos complejos. Sin embargo,
          te animo a profundizar más en las lecturas asignadas y mejorar tus
          habilidades de citación en tus ensayos. Participar de manera más
          crítica con el material mejorará tus análisis y escritura académica.
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Editar</a>
          <a href="javascript:void(0);">Eliminar</a>
        </div>
      </div>
    </div>
    <!-- /Reseña -->

    <!-- Reseña -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>hace 9 meses</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          George, has hecho un progreso encomiable en la comprensión de los
          conceptos básicos de la ciencia de datos. Tu proyecto mostró un buen
          dominio de las técnicas de manipulación de datos. Sin embargo, he
          observado desafíos en tu enfoque hacia la modelación estadística,
          donde se necesita una comprensión más clara de los conceptos.
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Editar</a>
          <a href="javascript:void(0);">Eliminar</a>
        </div>
      </div>
    </div>
    <!-- /Reseña -->

    <!-- Reseña -->
    <div class="review-wrap">
      <div class="review-user-info">
        <div class="reviewer">
          <div class="review-img">
            <a href="javascript:void(0);"
              ><img src="@/assets/img/user/user-17.jpg" alt="img"
            /></a>
          </div>
          <div class="reviewer-info">
            <h6>
              <a href="javascript:void(0);">Eugene Andre</a>
            </h6>
            <p>hace 1 año</p>
          </div>
        </div>
        <div class="reviewer-rating">
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star filled"></i>
          <i class="fa-solid fa-star"></i>
        </div>
      </div>
      <div class="review-content">
        <p>
          Lisa, mientras que tus tareas para "Introducción a la Filosofía"
          muestran una comprensión básica, te animo a involucrarte más
          profundamente con los materiales del curso y participar en las
          discusiones del foro. Compartir tus pensamientos y preguntas puede
          mejorar enormemente tu comprensión y contribuir a nuestra comunidad de
          clase.
        </p>
        <div class="review-action">
          <a href="javascript:void(0);">Editar</a>
          <a href="javascript:void(0);">Eliminar</a>
        </div>
      </div>
    </div>
    <!-- /Reseña -->
  </div>
</template>
