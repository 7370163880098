<template>
  <student-header></student-header>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div >
    <div class="container">
      <div class="row">
        <!-- barra lateral -->
        <student-sidebar class="page-content fondoSettings"></student-sidebar>
        <!-- /Barra lateral -->

        <!-- Configuración del Estudiante -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Mi Perfil</h3>
                <p>
                  Tienes control total para gestionar la configuración de tu
                  cuenta
                </p>
              </div>
              <form action="student-settings">
                <div class="course-group profile-upload-group mb-0 d-flex">
                  <div
                    class="course-group-img profile-edit-field d-flex align-items-center"
                  >
                    <div class="profile-pic">
                      <img :src="src" alt="Img" class="img-fluid" />
                    </div>
                    <div class="profile-upload-head">
                      <h4>
                        <router-link to="/student/student-profile"
                          >Tu avatar</router-link
                        >
                      </h4>
                      <p>PNG o JPG no mayor a 800px de ancho y alto</p>
                      <div class="new-employee-field">
                        <div class="d-flex align-items-center mt-2">
                          <FileUpload
                            mode="basic"
                            @select="onFileSelect"
                            customUpload
                            auto
                            choose-icon="pi pi-images"
                            chooseLabel=" "
                            severity="secondary"
                            class="p-button-outlined"
                          />
                          <div class="img-delete" v-if="false">
                            <a href="#" class="delete-icon"
                              ><i class="bx bx-trash"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-form settings-wrap">
                  <div class="edit-profile-info">
                    <h5>Detalles Personales</h5>
                    <p>Edita tu información personal</p>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Nombre</span>
                        <InputText
                          v-model="estudiante.nombres"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Apellido</span>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.apellidos"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Nombre de Usuario o Correo</span>
                        <input
                          type="text"
                          class="form-control"
                          v-model="user.email"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Número de Teléfono</span>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.telefono"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Biografía</span>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.biografia"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Direccion</span>
                        <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.direccion"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Paiz</span>
                        <!-- <input
                          type="text"
                          class="form-control"
                          v-model="estudiante.pais"
                        /> -->
                        <AutoComplete
                          v-model="pais"
                          dropdown
                          :suggestions="paises"
                          placeholder="Seleccione un pais"
                          optionLabel="nombre"
                          optionValue="id"
                          @complete="searchPaises"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <Button
                        label="Actualizar Estudiante"
                        class="btn btn-primary"
                        @click="actualizarEstudiante"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Configuración del Estudiante -->
      </div>
    </div>
  </div>
</template>

<script>
import EstudianteService from "@/service/EstudianteService";
import UserService from "@/service/UserService";
import InputText from "primevue/inputtext";
import AutoComplete from "primevue/autocomplete";
import FileUpload from "primevue/fileupload";
import Button from "primevue/button";

export default {
  components: {
    InputText,
    AutoComplete,
    FileUpload,
    Button,
  },
  data() {
    return {
      title: "Configuraciones",
      text: "Inicio",
      text1: "Configuraciones",
      estudiante: {},
      user: {},
      src: require("@/assets/img/user/avatar.png"),
      paises: [],
      pais: {},
      selectedFile: null,
    };
  },
  created() {
    this.estudianteService = new EstudianteService();
    this.userService = new UserService();
  },
  mounted() {
    this.obtenerEstudiante();
  },
  methods: {
    obtenerEstudiante() {
      this.userService.getUser().then((response) => {
        if(!response.success) {
          return;
        }
        this.user = response.user;
        this.estudiante = response.usuario;
        this.pais = response.usuario.pais ?? null;
        this.src = response.usuario.foto
        ? response.usuario.foto
        : require("@/assets/img/user/avatar.png");
      });
    },
    searchPaises(event) {
      const query = {
        dato: event.query,
      };
      this.estudianteService.buscarPais(query).then((response) => {
        this.paises = response.pais;
      });
    },
    onFileSelect(event) {
      const file = event.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.src = e.target.result;
        this.selectedFile = reader.result.split(',')[1]; // Obtiene la cadena Base64 sin el encabezado
      };
      reader.readAsDataURL(file);
    },
    uploadImage() {
      if (!this.selectedFile) {
        return;
      }

      const payload = {
        image: this.selectedFile,
        name : this.estudiante.nombres ?? 'estudiante',
      };
      this.estudianteService.uploadImage(payload).then((response) => {
        if (!response.success) {
          this.$message.success(response.message);
        }
      });
    },
    actualizarEstudiante() {
      const data_enviar = {
        ...this.estudiante,
        pais_id: this.pais.id ?? null,
        email: this.user.email ?? null,
        password: this.user.password ?? null,
      };
      this.uploadImage();
      this.estudianteService.updateEstudiante(data_enviar).then((response) => {
        if (response.success) {
          this.$message.success(response.message);
          this.obtenerEstudiante();
        } else {
          this.$message.error(response.message);
        }
      });
    },
  },
};
</script>
