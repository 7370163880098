<template>
  <!-- Filtro -->
  <div class="showing-list">
    <div class="row justify-content-between">
      <div class="col-4">
        <div class="d-flex align-items-center">
          <div class="view-icons">
            <router-link
              :class="
                currentPath == 'instructor-grid' || currentPath == 'instructor-grid-2'
                  ? 'active'
                  : 'notactive'
              "
              to="instructor-grid"
              class="grid-view"
              ><i class="feather-grid"></i
            ></router-link>
            <router-link v-if="false"
              :class="currentPath == 'instructor-list' ? 'active' : 'notactive'"
              to="/instructor/instructor-list"
              class="list-view"
              ><i class="feather-list"></i
            ></router-link>
          </div>
        </div>
      </div>
      <div class="col-4">
        <router-link 
          to="/register-step-one"
          class="btn btn-primary btn-lg"
          >
          <i class="pi pi-user-plus"></i> Nuevo instructor          
        </router-link>
      </div>
    </div>
  </div>
  <!-- /Filtro -->
</template>

<script>
export default {
  computed: {
    currentPath() {
      return this.$route.name;
    },
  },
  data() {
    return {
      published: ["Nuevos publicados", "Angular", "React", "Nodejs"],
    };
  },
};
</script>
