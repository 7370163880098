<template>
  <layouts-instructorborder></layouts-instructorborder>

  <student-breadcrumb :title="title" :text="text" :text1="text1" />

  <!-- Contenido de la Página -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- barra lateral -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Barra lateral -->

        <!-- Perfil del Instructor -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details mb-0">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Mi Perfil</h3>
              </div>
              <div class="checkout-form personal-address">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Nombre</h6>
                      <p>{{ instructor.nombres }}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Apellido</h6>
                      <p>{{ instructor.apellidos }}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Fecha de Registro</h6>
                      <p>{{ new Date(instructor.created_at).toLocaleString() }}</p>
                    </div>
                  </div>
                  <div class="col-sm-6" v-if="false">
                    <div class="contact-info">
                      <h6>Nombre de Usuario</h6>
                      <p>instructordemo</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Email</h6>
                      <p>{{ user.email }}</p>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="contact-info">
                      <h6>Número de Teléfono</h6>
                      <p>{{ instructor.telefono ?? 'Sin Numero de Telefono'}}</p>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="contact-info mb-0">
                      <h6>Biografía</h6>
                      <p>
                        {{ instructor.biografia ?? 'Sin Biografía' }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /Perfil del Instructor -->
      </div>
    </div>
  </div>
  <!-- /Contenido de la Página -->

  <layouts1></layouts1>
</template>
<script>
import UserService from '@/service/UserService';
export default {
  data() {
    return {
      title: "Mi Perfil",
      text: "Inicio",
      text1: "Mi Perfil",
      instructor: {},
      user: {},
      src: require("@/assets/img/user/avatar.png"),
    };
    
  },
  created() {
    this.userService = new UserService();
  },
  mounted() {
    this.obtenerInstructor();
  },
  methods: {
    obtenerInstructor() {
      this.userService.getUser().then((response) => {
        this.user = response.user;
        if (!response.success) {
          return;
        }
        if (this.user.tipo_usuario === 4) {
          return;
        }
        this.instructor = response.usuario;
        this.src = response.usuario.foto 
        ?response.usuario.foto
        : require("@/assets/img/user/avatar.png");
      });
    },
  },
};
</script>
