<template>
    <layouts-login></layouts-login>

    <!-- Course Lesson -->
    <section class="page-content course-sec course-lesson">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <!-- Course Lesson -->
            <div class="lesson-group">
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#collapseOne"
                    aria-expanded="false"
                    >Chapter 1 <span>5 Lessons</span>
                  </a>
                </h6>
                <div id="collapseOne" class="card-collapse collapse" style="">
                  <div class="progress-stip">
                    <div
                      class="progress-bar bg-success progress-bar-striped active-stip"
                    ></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>10hrs<span>50%</span></p>
                  </div>
                  <ul>
                    <li>
                      <p class="play-intro">Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/play-icon.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Course Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Exam</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Course</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Building Our Scenario</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Learnings</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#course2"
                    aria-expanded="false"
                    >Chapter 2 <span>8 Lessons</span>
                  </a>
                </h6>
                <div id="course2" class="card-collapse collapse" style="">
                  <div class="progress-stip">
                    <div
                      class="progress-bar bg-success progress-bar-striped active-stip"
                    ></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>10hrs<span>50%</span></p>
                  </div>
                  <ul>
                    <li>
                      <p class="play-intro">Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/play-icon.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Course Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Exam</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Course</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Building Our Scenario</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Learnings</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#course3"
                    aria-expanded="false"
                    >Chapter 3 <span>7 Lessons</span>
                  </a>
                </h6>
                <div id="course3" class="card-collapse collapse" style="">
                  <div class="progress-stip">
                    <div
                      class="progress-bar bg-success progress-bar-striped active-stip"
                    ></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>12hrs<span>50%</span></p>
                  </div>
                  <ul>
                    <li>
                      <p class="play-intro">Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/play-icon.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Course Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Exam</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Course</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Building Our Scenario</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Learnings</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#coursefour"
                    aria-expanded="false"
                    >Chapter 4 <span>5 Lessons</span>
                  </a>
                </h6>
                <div id="coursefour" class="card-collapse collapse">
                  <div class="progress-stip">
                    <div
                      class="progress-bar bg-success progress-bar-striped active-stip"
                    ></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>8hrs<span>50%</span></p>
                  </div>
                  <ul>
                    <li>
                      <p class="play-intro">Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/play-icon.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Course Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Exam</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Course</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Building Our Scenario</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Learnings</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="course-card">
                <h6 class="cou-title">
                  <a
                    class="collapsed"
                    data-bs-toggle="collapse"
                    href="#coursefive"
                    aria-expanded="false"
                    >Chapter 5 <span>8 Lessons</span>
                  </a>
                </h6>
                <div id="coursefive" class="card-collapse collapse">
                  <div class="progress-stip">
                    <div
                      class="progress-bar bg-success progress-bar-striped active-stip"
                    ></div>
                  </div>
                  <div class="student-percent lesson-percent">
                    <p>15hrs<span>40%</span></p>
                  </div>
                  <ul>
                    <li>
                      <p class="play-intro">Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/play-icon.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Course Introduction</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Exam</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>About the Course</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Building Our Scenario</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                    <li>
                      <p>Learnings</p>
                      <div>
                        <img src="@/assets/img/icon/lock.svg" alt="" />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!-- /Course Lesson -->
          </div>
          <div class="col-lg-8">
            <!-- Introduction -->
            <div class="student-widget lesson-introduction">
              <div class="lesson-widget-group">
                <h4 class="tittle">Introduction</h4>
                <div class="introduct-video">
                  <a
                    href="https://www.youtube.com/embed/1trvO6dqQUI"
                    class="video-thumbnail"
                    data-fancybox=""
                  >
                    <div class="play-icon">
                      <i class="fa-solid fa-play"></i>
                    </div>
                    <img class="" src="@/assets/img/video-img-01.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>

            <!-- /Introduction -->
          </div>
        </div>
      </div>
    </section>
    <!-- /Course Lesson -->

    <layouts1></layouts1>
</template>
