<template>
  <layouts-login></layouts-login>
  <blog-modern></blog-modern>

  <!-- Blog Modern -->
  <section class="course-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="row">
            <div class="col-md-4 col-sm-12">
              <!-- Publicación del Blog -->
              <div class="blog grid-modern">
                <div class="blog-image">
                  <router-link to="/blog/blog-details"
                    ><img class="img-fluid" src="@/assets/img/blog/blog-14.jpg" alt=""
                  /></router-link>
                </div>
                <div class="blog-modern-box">
                  <h3 class="blog-title">
                    <router-link to="/blog/blog-details"
                      >Aprende Desarrollo de Aplicaciones Web con Expertos</router-link
                    >
                  </h3>
                  <div class="blog-info clearfix mb-0">
                    <div class="post-left">
                      <ul>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-22.svg"
                            alt=""
                          />20 de enero, 2023
                        </li>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-24.svg"
                            alt=""
                          />Programación, Angular
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Publicación del Blog -->
            </div>
            <div class="col-md-4 col-sm-12">
              <!-- Publicación del Blog -->
              <div class="blog grid-modern">
                <div class="blog-image">
                  <router-link to="/blog/blog-details"
                    ><img class="img-fluid" src="@/assets/img/blog/blog-15.jpg" alt=""
                  /></router-link>
                </div>
                <div class="blog-modern-box">
                  <h3 class="blog-title">
                    <router-link to="/blog/blog-details"
                      >Amplía tus Oportunidades de Carrera con Python</router-link
                    >
                  </h3>
                  <div class="blog-info clearfix mb-0">
                    <div class="post-left">
                      <ul>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-22.svg"
                            alt=""
                          />10 de junio, 2023
                        </li>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-24.svg"
                            alt=""
                          />Programación, Diseño Web
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Publicación del Blog -->
            </div>
            <div class="col-md-4 col-sm-12">
              <!-- Publicación del Blog -->
              <div class="blog grid-modern">
                <div class="blog-image">
                  <router-link to="/blog/blog-details"
                    ><img class="img-fluid" src="@/assets/img/blog/blog-16.jpg" alt=""
                  /></router-link>
                </div>
                <div class="blog-modern-box">
                  <h3 class="blog-title">
                    <router-link to="/blog/blog-details"
                      >Aprende Desarrollo de Aplicaciones Móviles con Expertos</router-link
                    >
                  </h3>
                  <div class="blog-info clearfix mb-0">
                    <div class="post-left">
                      <ul>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-22.svg"
                            alt=""
                          />14 de marzo, 2023
                        </li>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-24.svg"
                            alt=""
                          />Programación, React
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Publicación del Blog -->
            </div>
            <div class="col-md-4 col-sm-12">
              <!-- Publicación del Blog -->
              <div class="blog grid-modern">
                <div class="blog-image">
                  <router-link to="/blog/blog-details"
                    ><img class="img-fluid" src="@/assets/img/blog/blog-17.jpg" alt=""
                  /></router-link>
                </div>
                <div class="blog-modern-box">
                  <h3 class="blog-title">
                    <router-link to="/blog/blog-details"
                      >Guía Completa de Carrera en Programación PHP</router-link
                    >
                  </h3>
                  <div class="blog-info clearfix mb-0">
                    <div class="post-left">
                      <ul>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-22.svg"
                            alt=""
                          />19 de junio, 2023
                        </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programación, Diseño Web
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Publicación del Blog -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Publicación del Blog -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-18.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Guía de Contenidos de Programación para Principiantes</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />05 de noviembre, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programación, PHP
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Publicación del Blog -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Publicación del Blog -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-19.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >El Curso Completo de JavaScript para Principiantes</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />11 de mayo, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programación, Diseño Web
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Publicación del Blog -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Publicación del Blog -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-14.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Aprende Desarrollo de Aplicaciones Web con Expertos</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />14 de junio, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programación, Curso
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Publicación del Blog -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Publicación del Blog -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-15.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Amplía tus Oportunidades de Carrera con Python</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />18 de mayo, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programación, Diseño Web
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Publicación del Blog -->
              </div>
              <div class="col-md-4 col-sm-12">
                <!-- Publicación del Blog -->
                <div class="blog grid-modern">
                  <div class="blog-image">
                    <router-link to="/blog/blog-details"
                      ><img class="img-fluid" src="@/assets/img/blog/blog-16.jpg" alt=""
                    /></router-link>
                  </div>
                  <div class="blog-modern-box">
                    <h3 class="blog-title">
                      <router-link to="/blog/blog-details"
                        >Aprende Desarrollo de Aplicaciones Móviles con Expertos</router-link
                      >
                    </h3>
                    <div class="blog-info clearfix mb-0">
                      <div class="post-left">
                        <ul>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-22.svg"
                              alt=""
                            />23 de junio, 2023
                          </li>
                          <li>
                            <img
                              class="img-fluid"
                              src="@/assets/img/icon/icon-24.svg"
                              alt=""
                            />Programación, Curso
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- /Publicación del Blog -->
              </div>
            </div>

            <pagination></pagination>
          </div>
        </div>
      </div>
    </section>
    <!-- /Blog Modern -->

    <layouts1></layouts1>
</template>
