<template>
    <div class="error-page">

        <div class="error-box">
            <div class="error-logo">
                <router-link to="/home/">
                    <img src="@/assets/img/logo.svg" class="img-fluid" alt="Logo">
                </router-link>
            </div>
            <div class="error-box-img">
                <img src="@/assets/img/error-02.png" alt="" class="img-fluid">
            </div>
            <h3 class="h2 mb-3"> El sitio web está en construcción</h3>
            <p class="h4 font-weight-normal">Estamos trabajando para solucionar el problema. Volveremos pronto.</p>
            <router-link to="/home/" class="btn btn-primary">Volver a Inicio</router-link>
        </div>

    </div>
</template>
