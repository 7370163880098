<template>
<!-- Course Header -->
<div class="page-banner instructor-bg-blk">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="instructor-profile d-flex align-items-center mb-0">
                    <div class="instructor-profile-pic">
                        <router-link to="/instructor/instructor-profile">
                            <img src="@/assets/img/instructor/profile-avatar.jpg" alt="" class="img-fluid">
                        </router-link>
                    </div>
                    <div class="instructor-profile-content">
                        <h4><router-link to="/instructor/instructor-profile">Jenny Wilson <span>Beginner</span></router-link></h4>
                        <p>Instructor</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /Course Header -->
</template>