<template>
  <div class="chat-page main-chat-blk">
    <div class="main-wrapper chat-wrapper">
      <student-header></student-header>
      <student-breadcrumb :title="title" :text="text" :text1="text1" />
      <div class="page-content chat-page-wrapper">
        <div class="container">
          <div class="row">
            <!-- Sidebar -->
            <student-sidebar></student-sidebar>
            <!-- /Sidebar -->

            <!-- Perfil del Estudiante -->
            <div class="col-xl-9 col-lg-9 theiaStickySidebar">
              <div class="stickysidebar">
                <div class="settings-widget card-details mb-0">
                  <div class="settings-menu p-0">
                    <div class="profile-heading">
                      <h3>Mensaje</h3>
                    </div>
                    <div class="checkout-form">
                      <!-- Grupo de sidebar -->
                      <div class="content">
                        <div class="sidebar-group left-sidebar chat_sidebar">
                          <!-- Sidebar de Chats -->

                          <div
                            id="chats"
                            class="left-sidebar-wrap sidebar active slimscroll"
                          >
                            <perfect-scrollbar
                              class="scroll-area"
                              :settings="settings"
                              @ps-scroll-y="scrollHanle"
                            >
                              <div class="slimscroll">
                                <!-- Título de Chat Izquierdo -->
                                <div
                                  class="left-chat-title all-chats d-flex justify-content-between align-items-center"
                                >
                                  <div class="select-group-chat">
                                    <div class="dropdown">
                                      <a href="javascript:void(0);">
                                        Todos los Chats
                                      </a>
                                    </div>
                                  </div>
                                  <div class="add-section">
                                    <ul>
                                      <li>
                                        <a
                                          href="javascript:void(0);"
                                          class="user-chat-search-btn"
                                          ><i class="feather-search"></i
                                        ></a>
                                      </li>
                                    </ul>
                                    <!-- Búsqueda de Chat -->
                                    <div class="user-chat-search">
                                      <form>
                                        <span class="form-control-feedback"
                                          ><i class="feather-search"></i
                                        ></span>
                                        <input
                                          type="text"
                                          name="chat-search"
                                          placeholder="Buscar"
                                          class="form-control"
                                        />
                                        <div class="user-close-btn-chat">
                                          <i class="feather-x"></i>
                                        </div>
                                      </form>
                                    </div>
                                    <!-- /Búsqueda de Chat -->
                                  </div>
                                </div>
                                <!-- /Título de Chat Izquierdo -->

                                <!-- Contactos en Línea Destacados -->
                                <top-online></top-online>
                                <!-- /Contactos en Línea Destacados -->

                                <div
                                  class="sidebar-div chat-body"
                                  id="chatsidebar"
                                >
                                  <!-- Título de Chat Izquierdo -->
                                  <div
                                    class="d-flex justify-content-between align-items-center ps-0 pe-0"
                                  >
                                    <div class="fav-title pin-chat">
                                      <h6>Chat Fijado</h6>
                                    </div>
                                  </div>
                                  <!-- /Título de Chat Izquierdo -->

                                  <pinned-chat></pinned-chat>
                                  <!-- Título de Chat Izquierdo -->
                                  <div
                                    class="d-flex justify-content-between align-items-center ps-0 pe-0"
                                  >
                                    <div class="fav-title pin-chat">
                                      <h6>Chat Reciente</h6>
                                    </div>
                                  </div>
                                  <!-- /Título de Chat Izquierdo -->
                                  <recent-chat></recent-chat>
                                </div>
                              </div>
                            </perfect-scrollbar>
                          </div>
                          <!-- /Sidebar de Chats -->
                        </div>
                        <!-- /Grupo de Sidebar -->

                        <!-- Chat -->
                        <div class="chat chat-messages" id="middle">
                          <div class="h-100">
                            <studentchat-header></studentchat-header>
                            <div class="chat-body chat-page-group slimscroll">
                              <perfect-scrollbar
                                class="scroll-area-one"
                                :settings="settings"
                                @ps-scroll-y="scrollHanle"
                              >
                                <chat-message></chat-message>
                              </perfect-scrollbar>
                            </div>
                          </div>
                          <chat-footer></chat-footer>
                        </div>
                        <!-- /Chat -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Perfil del Estudiante -->
          </div>
        </div>
      </div>
      <layouts1></layouts1>
    </div>
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
export default {
  components: {
    PerfectScrollbar,
  },
  data() {
    return {
      title: "Mensajes",
      text: "Inicio",
      text1: "Mensajes",
      settings: {
        suppressScrollX: true,
      },
    };
  },
  methods: {
    scrollHanle() {},
  },
};
</script>
