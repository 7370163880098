import { defineStore } from "pinia";
import { router } from "@/router";

const ruta_api = process.env.VUE_APP_ROOT_API;

export const useAuth = defineStore({
  id: "auth",
  state: () => ({
    token: localStorage.getItem("token") || "",
    user: JSON.parse(localStorage.getItem("user")),
    estudiantes: null, // Nueva propiedad para almacenar datos de estudiantes
    returnUrl: null,
  }),
  actions: {
    async userSignup(data) {
      const res = await fetch("http://localhost:8000/api/register", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const output = await res.json();
      if (output.success) {
        alert(output.message);
        data.name = "";
        data.email = "";
        data.telefono = "";
      } else {
        alert(output.message);
      }
    },
    async userLogin(data) {
      const res = await fetch(ruta_api + "/login", {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const output = await res.json();
      if (output.success) {
        localStorage.setItem("token", output.token);

        this.token = output.token;

        // update pinia state
        this.user = output.user;
        this.user.permissions = output.permissions;

        localStorage.setItem("user", JSON.stringify(output.user));

        // Obtener datos de estudiante después del inicio de sesión
       /*  await this.getEstudianteData(output.user.id); */
       console.log(this.user);
        if (this.user.tipo_usuario === 4) {
          router.push(this.returnUrl || "/student/student-settings/");
        }else{
          router.push(this.returnUrl || "/home/");
        }
      } else {
        alert(output.mensaje);
      }
    },
    async getUser() {
      const res = await fetch("http://localhost:8000/api/user", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const output = await res.json();
      this.user = output.user;

      // Obtener datos de estudiante con el ID del usuario
      /* await this.getEstudianteData(output.user.id); */
    },
    // Nueva acción para obtener los datos de estudiantes
    async getEstudianteData(userId) {
      const res = await fetch(`http://localhost:8000/api/estudiantes/${userid}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const output = await res.json();
      if (output.success) {
        this.estudiantes = output.estudiantes; // Asignar datos del estudiante
      } else {
        console.error("Error al obtener datos del estudiante:", output.message);
      }
    },
    userLogout() {
      this.token = "";
      this.user = "";
      this.estudiantes = null; // Resetear el estado de estudiante
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      router.push({ name: "login" });
    },
  },
});
