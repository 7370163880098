import { useAuth } from "@/stores";

export const fetchWrapper = {
  get: request("GET"),
  getWithBlob: requestBlob("GET"), //EXCEL
  postWithBlob: requestBlob("POST"), //EXCEL
  getPdf: requestPDF("GET"), //PDF
  postPdf: requestPDF("POST"), //PDF
  post: request("POST"),
  postFiles: requestpostFiles("POST"),
  put: request("PUT"),
  delete: request("DELETE"),
};

function request(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}
function requestpostFiles(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.body = body;
    }
    return fetch(url, requestOptions).then(handleResponse);
  };
}
function requestBlob(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponseBlob);
  };
}
function requestPDF(method) {
  return (url, body) => {
    const requestOptions = {
      method,
      headers: authHeader(url),
    };
    if (body) {
      requestOptions.headers["Content-Type"] = "application/json";
      requestOptions.body = JSON.stringify(body);
    }
    return fetch(url, requestOptions).then(handleResponsePDF);
  };
}

// helper functions
function authHeader(url) {
  const { token } = useAuth();
  const isApiUrl = url.startsWith(process.env.VUE_APP_ROOT_API);

  // Solo incluir el token si está disponible y la ruta está protegida
  if (token && isApiUrl) {
    return { Authorization: `Bearer ${token}` };
  } else {
    return {};
  }
}

function handleResponseBlob(response) {
  if (response.ok) {
    response.blob().then(function (miBlob) {
      /* var objectURL = URL.createObjectURL(miBlob);
      miImagen.src = objectURL; */
      var fileURL = window.URL.createObjectURL(new Blob([miBlob]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        new Date().toLocaleString().replace(/\D/g, "") + ".xlsx"
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } else {
    console.log("Respuesta de red OK pero respuesta HTTP no OK");
  }
}
function handleResponsePDF(response) {
  if (response.ok) {
    response.blob().then(function (miBlob) {
      /* var objectURL = URL.createObjectURL(miBlob);
      miImagen.src = objectURL; */
      var fileURL = window.URL.createObjectURL(new Blob([miBlob]));
      var fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        new Date().toLocaleString().replace(/\D/g, "") + ".pdf"
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  } else {
    console.log("Respuesta de red OK pero respuesta HTTP no OK");
  }
}

function handleResponse(response) {
  const contentType = response.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
        const { token, logout } = useAuth();
        if ([401, 403].includes(response.status) && token) {
          logout();
        }
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
      return data;
    });
  } else {
    // Manejar error cuando el servidor devuelve HTML en lugar de JSON
    return Promise.reject(
      "Error: El servidor devolvió HTML en lugar de JSON. Verifica la autenticación y rutas."
    );
  }
}
