<template>
  <!-- Feature Course -->
  <section class="cursos_portada" id="cursos_portada">
    <div class="container">
      <div class="section-header aos" data-aos="fade-up">
        <div class="section-sub-head">
          <span style="color: #f7f1ee" class="texto_cursos"
            >Qué hay de nuevo
          </span>
          <h2 style="color: #f7f1ee">Cursos destacados</h2>
        </div>
        <div class="all-btn all-category d-flex align-items-center">
          <router-link
            to="/course/course-grid"
            class="btn btn-primary"
            style="color: #f7f1ee"
            ><i class="fas fa-th-large"> </i> Todos los cursos</router-link
          >
        </div>
      </div>
      <div class="course-feature">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 d-flex"
            v-for="curso in cursos"
            :key="curso.id"
          >
            <div class="course-box d-flex aos" data-aos="fade-up">
              <div class="product">
                <div class="product-img">
                  <router-link :to="`/course/course-details/${curso.id}`">
                    <img
                      class="img-fluid"
                      :alt="curso.nombre"
                      :src="curso.imagen"
                    />
                  </router-link>
                  <div class="price">
                    <h3>Bs. {{ curso.precio }} <span>Bs. 500.00</span></h3>
                  </div>
                </div>
                <div class="product-content">
                  <div class="course-group d-flex">
                    <div class="course-group-img d-flex">
                      <img
                        :src="curso.foto_instructor"
                        alt=""
                        class="img-fluid"
                      />
                      <div class="course-name">
                        <h4>
                          {{ curso.instructor }}
                        </h4>
                        <p>Instructor</p>
                      </div>
                    </div>
                    <div
                      v-if="false"
                      class="course-share d-flex align-items-center justify-content-center"
                    >
                      <a href="javascript:void(0);">
                        <i class="fa-regular fa-heart"></i>
                      </a>
                    </div>
                  </div>
                  <h3 class="titulo-h3">
                    <router-link :to="`/course/course-details/${curso.id}`">
                      {{ curso.nombre }}
                    </router-link>
                  </h3>
                  <div class="course-info d-flex align-items-center">
                    <div class="rating-img d-flex align-items-center">
                      <img src="@/assets/img/icon/icon-01.svg" alt="" />
                      <p>{{ curso.temas }}+ Lección</p>
                    </div>
                    <div class="course-view d-flex align-items-center">
                      <img src="@/assets/img/icon/icon-02.svg" alt="" />
                      <p>{{ curso.duracion }}</p>
                    </div>
                  </div>
                  <!--  <div class="btn-comprar d-flex align-items-center">
                          <router-link
                            to="/pages/checkout"
                            class="btn btn-primary btn-comprar"
                          >
                            COMPRAR
                          </router-link>
                        </div> -->
                  <div class="d-flex align-items-center w-100">
                    <router-link
                      to="/pages/checkout"
                      class="btn btn-cuadrado"
                    >
                      <span><i class="fas fa-shopping-cart"></i> COMPRAR</span>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Feature Course -->

  <!-- Master Skill -->
  <section class="section master-skill" v-if="false">
    <div class="container">
      <div class="row">
        <div class="col-lg-7 col-md-12">
          <div class="section-header aos" data-aos="fade-up">
            <div class="section-sub-head">
              <span>Qué hay de nuevo</span>
              <h2>Domina las habilidades para impulsar tu carrera</h2>
            </div>
          </div>
          <div class="section-text aos" data-aos="fade-up">
            <p>
              Obtenga una certificación, domine las habilidades tecnológicas
              modernas y mejore su carrera, ya sea estás empezando o eres un
              profesional experimentado. El 95% de los estudiantes de eLearning
              reportan nuestra El contenido práctico ayudó directamente a sus
              carreras.
            </p>
          </div>
          <div class="career-group aos" data-aos="fade-up">
            <div class="row">
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-1.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>Manténgase motivado con instructores atractivos</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-2.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>Manténgase al día con lo último en la nube</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-3.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>
                      Obtenga la certificación con más de 100 cursos de
                      certificación
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 d-flex">
                <div class="certified-group blur-border d-flex">
                  <div class="get-certified d-flex align-items-center">
                    <div class="blur-box">
                      <div class="certified-img">
                        <img
                          src="@/assets/img/icon/icon-4.svg"
                          alt=""
                          class="img-fluid"
                        />
                      </div>
                    </div>
                    <p>
                      Desarrolle habilidades a su manera, desde laboratorios
                      hasta cursos
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-md-12 d-flex align-items-end">
          <div class="career-img aos" data-aos="fade-up">
            <img src="@/assets/img/join.png" alt="" class="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Master Skill -->

  <section class="PapoNetwork" id="PapoNetwork">
    <!-- Primera mitad -->
    <div class="half-section white-bg">
      <div class="content">
        <h2 class="title">¿Qué es PapoNetwork ?</h2>
       <!--  <p>
          Es un lugar donde aprender programación
          <strong>sin falsas promesas</strong>, a tu ritmo, con apoyo y junto a
          una comunidad con tus mismos propósitos.
        </p>
        <p>
          <strong
            >Es la manera de proporcionarte contenido extra y personalizado para
            ayudarte a mejorar.</strong
          >
        </p>
        <ul class="benefits-list">
          <li>Cursos reeditados por lección</li>
          <li>Cursos exclusivos</li>
          <li>Ejercicios, test y material adicional</li>
          <li>Comunidad privada de estudiantes, Discord y soporte</li>
          <li>Reuniones grupales cada semana</li>
          <li>Certificado de finalización</li>
        </ul>
        <p class="note">
          <i class="fas fa-shield-alt"></i> Recuerda que un certificado no es
          garantía. Lo más importante siempre es lo que aprendes y cómo eres
          capaz de demostrarlo de manera práctica.
        </p> -->
        <button class="btn btn-cuadrado">Quiero saber más</button>
      </div>
    </div>

    <!-- Segunda mitad -->
    <div class="half-section black-bg">
      <div class="content">
        <h2 class="title">¿Quién es PapoNetwork?</h2>
        <!-- <p class="texto-blanco">
          Mi nombre es <strong>Brais Moure</strong>, soy ingeniero de software
          desde 2010, freelance desde 2015 y creador de contenido sobre
          programación y desarrollo.
        </p>
        <p class="texto-blanco">
          He tenido la oportunidad de trabajar en empresas multinacionales,
          colaborar en numerosos proyectos y participar como ponente en
          conferencias <strong>internacionales</strong>.
        </p>
        <p class="texto-blanco">
          Desde 2018 comparto contenido <strong>gratuito</strong> para ayudar a
          democratizar el aprendizaje dentro de la comunidad de habla hispana.
          Juntos hemos formado una de las comunidades más grandes del
          <strong>mundo</strong>.
        </p>
        <p class="texto-blanco">
          Y esto <strong>NO va a cambiar</strong>, ya que PapoNetwork me ayudará
          a seguir haciéndolo de manera independiente, cercana y fiel a mis
          principios.
        </p>
        <p class="texto-blanco">
          Puedes encontrarme en
          <a href="#" class="texto-blanco">
            <i class="fab fa-youtube"></i>
            YouTube</a
          >,<a href="#" class="texto-blanco">
            <i class="fab fa-twitter"></i>
            Instagram</a
          >,
          <a href="#" class="texto-blanco">
            <i class="fab fa-tiktok"></i>
            TikTok</a
          >,
          <a href="#" class="texto-blanco">
            <i class="fab fa-linkedin"></i>
            LinkedIn</a
          >.
        </p> -->
        <button class="btn btn-redondeado">Acceder al contenido gratuito</button>
      </div>
    </div>
  </section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import CursoService from "@/service/CursoService";
export default {
  data() {
    return {
      cursos: [],
    };
  },

  cursoService: null,

  mounted() {
    this.$nextTick(() => {
      this.obtenerCursos();
      AOS.init();
    });
  },

  created() {
    this.cursoService = new CursoService();
  },

  methods: {
    obtenerCursos() {
      this.cursoService
        .obtenerCursos()
        .then((response) => {
          this.cursos = response.cursos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style>
.btn-comprar {
  display: block; /* Ancho completo */
  width: 100%; /* Responsivo: se adapta al contenedor */
  text-align: center; /* Centra el texto */
  padding: 15px 20px; /* Espaciado interno */
  font-size: 18px; /* Texto ligeramente más grande */
  font-weight: bold;
  text-transform: uppercase; /* Texto en mayúsculas */
  color: #171723; /* Texto blanco */
  background-color: #f7f1ee; /* Fondo inicial */
  border: 2px solid #171723; /* Borde */
  border-radius: 0; /* Bordes cuadrados */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative; /* Para controlar el pseudo-elemento */
  overflow: hidden; /* Contener animaciones */
  z-index: 1; /* Prioridad para el texto */
}

/* Efecto de fondo animado */
.btn-comprar::before {
  content: "";
  position: absolute;
  top: -100%; /* Empieza fuera del botón */
  left: 0;
  width: 100%; /* Ancho completo */
  height: 100%;
  background: linear-gradient(90deg, #233269, #171723); /* Degradado */
  z-index: 0; /* Detrás del texto */
  transition: all 0.4s ease-in-out;
}

/* Animación al pasar el cursor */
.btn-comprar:hover::before {
  top: 0; /* Fondo visible */
}

/* Cambios en el texto y borde al pasar el cursor */
.btn-comprar:hover {
  color: #f7f1ee;
  border-color: #171723;
}

/* Efecto de clic */
.btn-comprar:active {
  transform: scale(0.98);
}

/* El texto encima del pseudo-elemento */
.btn-comprar span {
  position: relative;
  z-index: 1;
}

/* Responsivo para tamaños pequeños */
@media (max-width: 768px) {
  .btn-comprar {
    font-size: 16px; /* Texto más pequeño */
    padding: 12px 15px; /* Ajusta el espaciado */
  }
}

@media (max-width: 480px) {
  .btn-comprar {
    font-size: 14px; /* Texto más compacto */
    padding: 10px 12px;
  }
}

/* SECCION DE QUE ES PAPO */
.PapoNetwork {
  display: flex;
  flex-direction: row;
  height: 100vh;
  font-family: "erie-2";
}

/* Mitades */
.half-section {
  position: relative;
  width: 50%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.white-bg {
  background: #f7f1ee;
}

.black-bg {
  background: #171723;
}
.black-bg .title {
  color: #f7f1ee;
}
.texto-blanco {
  color: #f7f1ee;
}

/* Cuadrículas animadas */
.half-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: 50px 50px;
  background-image: linear-gradient(
      to right,
      rgba(20, 18, 59, 0.147) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(20, 18, 59, 0.147) 1px, transparent 1px);
  animation: moverCuadricula 5s linear infinite;
}

/* Animación */
@keyframes grid-animation {
  0% {
    transform: translate(-50px, -50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Contenido */
.content {
  position: relative;
  z-index: 1;
  max-width: 600px;
  color: inherit;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

p {
  font-size: 1rem;
  line-height: 1.8;
  margin-bottom: 20px;
}

.benefits-list {
  list-style-type: square;
  margin-left: 20px;
}

.note {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.note i {
  margin-right: 10px;
}
</style>
