<template>
  <layouts-instructorborder v-if="auth.user"></layouts-instructorborder>
  <horizontal-header v-else></horizontal-header>
  <div class="quienes-somos">
    <h1>Quienes Somos</h1>
    <p>
      En <strong>TechnoBox</strong>, nos dedicamos a ofrecer cursos de alta
      calidad en diversas áreas, incluyendo tecnología, arte, idiomas y
      desarrollo personal. Nuestro objetivo es proporcionar a nuestros
      estudiantes las herramientas y conocimientos necesarios para sobresalir en
      su campo.
    </p>

    <h2>Nuestra Misión</h2>
    <p>
      Creemos que la educación transforma vidas. Nos comprometemos a crear un
      entorno de aprendizaje inclusivo y accesible, donde cada estudiante pueda
      crecer y alcanzar su máximo potencial.
    </p>

    <h2>Nuestros Cursos</h2>
    <ul>
      <li>Curso de Programación Web</li>
      <li>Diseño Gráfico</li>
      <li>Marketing Digital</li>
      <li>Idiomas para Todos</li>
      <li>Habilidades de Liderazgo</li>
    </ul>

    <h2>¿Por qué Elegirnos?</h2>
    <p>
      - <strong>Expertos en la Materia:</strong> Nuestro equipo está compuesto
      por profesionales con amplia experiencia en sus campos.<br />
      - <strong>Aprendizaje Flexible:</strong> Ofrecemos cursos en línea que se
      adaptan a tu ritmo y horarios.<br />
      - <strong>Comunidad de Aprendizaje:</strong> Al unirte a nosotros,
      formarás parte de una comunidad activa de estudiantes y profesionales.
    </p>

    <h2>Contáctanos</h2>
    <p>
      Para más información sobre nuestros cursos y cómo inscribirte, no dudes en
      <router-link to="/contacto">contactarnos</router-link>. ¡Estamos aquí para
      ayudarte a dar el siguiente paso en tu formación!
    </p>
  </div>
  <layouts1></layouts1>
</template>

<script>
import { useAuth } from "@/stores"; // Importar el store de autenticación

export default {
  name: "QuienesSomos",
  data(){
    return {
      auth: useAuth(), // Instancia del store de autenticación
    }
  }
};
</script>

<style scoped>
body {
  font-family: "Helvetica Neue", Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

.quienes-somos {
  max-width: 900px;
  margin: 50px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

h1 {
  color: #2c3e50;
  text-align: center;
}

h2 {
  color: #2980b9;
  margin-top: 20px;
}

p {
  color: #555;
}

ul {
  padding-left: 20px;
  color: #555;
}

li {
  margin-bottom: 10px;
}

strong {
  color: #2980b9;
}
</style>
