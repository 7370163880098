<template>
  <div class="tab-pane show active" id="today">
    <div class="table-responsive custom-table">
      <table class="table table-nowrap mb-0">
        <thead>
          <tr>
            <th>ID de Pedido</th>
            <th>Nombre del Curso</th>
            <th>Fecha</th>
            <th>Precio</th>
            <th>Estado</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>#2643</td>
            <td>
              <span class="title-course"
                >Construye Sitios Web Responsivos del Mundo Real con HTML5 y CSS3</span
              >
            </td>
            <td>24 de marzo de 2024</td>
            <td>$34</td>
            <td>En Espera</td>
            <td>
              <a href="javascript:void(0);" class="action-icon"
                ><i class="bx bxs-download"></i
              ></a>
            </td>
          </tr>
          <tr>
            <td>#2644</td>
            <td>
              <span class="title-course"
                >Diseña de A a Z (2024): Conviértete en un diseñador de aplicaciones</span
              >
            </td>
            <td>26 de marzo de 2024</td>
            <td>$40</td>
            <td>En Espera</td>
            <td>
              <a href="javascript:void(0);" class="action-icon"
                ><i class="bx bxs-download"></i
              ></a>
            </td>
          </tr>
          <tr>
            <td>#2645</td>
            <td>
              <span class="title-course">Aprende los Fundamentos de Angular Guía para Principiantes</span>
            </td>
            <td>12 de abril de 2024</td>
            <td>$25</td>
            <td>Completado</td>
            <td>
              <a href="javascript:void(0);" class="action-icon"
                ><i class="bx bxs-download"></i
              ></a>
            </td>
          </tr>
          <tr>
            <td>#2646</td>
            <td>
              <span class="title-course"
                >Construye Sitios Web Responsivos del Mundo Real con HTML5 y CSS3</span
              >
            </td>
            <td>16 de abril de 2024</td>
            <td>$35</td>
            <td>En Espera</td>
            <td>
              <a href="javascript:void(0);" class="action-icon"
                ><i class="bx bxs-download"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
