<template>
  <ul class="nav header-navbar-rht">
    <div class="main-nav mx-3">
      <main-nav></main-nav>
    </div>
    <li class="nav-item" v-if="false">
      <div>
        <a
          href="#"
          id="dark-mode-toggle"
          ref="darkModeToggle"
          class="dark-mode-toggle"
          @click="enableDarkMode"
        >
          <i class="fa-solid fa-moon"></i>
        </a>
        <a
          href="#"
          id="light-mode-toggle"
          class="dark-mode-toggle"
          ref="lightModeToggle"
          @click="disableDarkMode"
        >
          <i class="fa-solid fa-sun"></i>
        </a>
      </div>
    </li>
    <li class="nav-item" v-if="false">
      <router-link to="/student/student-messages"
        ><img src="@/assets/img/icon/messages.svg" alt="img"
      /></router-link>
    </li>
    <li class="nav-item cart-nav">
      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
        <i class="fas fa-shopping-cart"></i>
      </a>
      <div class="wishes-list dropdown-menu dropdown-menu-right">
        <div class="wish-header">
          <a href="javascript:void(0)">Ver carrito</a>
          <a href="javascript:void(0)" class="float-end">verificar</a>
        </div>
        <div class="wish-content">
          <ul>
            <li>
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link to="/pages/course-details">
                      <img alt="Img" src="@/assets/img/course/course-04.jpg" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link to="/pages/course-details"
                        >Aprende angular...</router-link
                      >
                    </h6>
                    <p>Por David Franco</p>
                    <h5>$200 <span>$99.00</span></h5>
                  </div>
                </div>
                <div class="remove-btn">
                  <a href="#" class="btn">Eliminar</a>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link to="/pages/course-details">
                      <img alt="Img" src="@/assets/img/course/course-14.jpg" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link to="/pages/course-details"
                        >Construya una realidad responsiva...</router-link
                      >
                    </h6>
                    <p>Jenis R.</p>
                    <h5>$200 <span>$99.00</span></h5>
                  </div>
                </div>
                <div class="remove-btn">
                  <a href="#" class="btn">Eliminar</a>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link to="/pages/course-details">
                      <img alt="Img" src="@/assets/img/course/course-15.jpg" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link to="/pages/course-details"
                        >Desarrolladores de C# doble...</router-link
                      >
                    </h6>
                    <p>Jesse Stevens</p>
                    <h5>$200 <span>$99.00</span></h5>
                  </div>
                </div>
                <div class="remove-btn">
                  <a href="#" class="btn">Eliminar</a>
                </div>
              </div>
            </li>
          </ul>
          <div class="total-item">
            <h6>Subtotal : $ 600</h6>
            <h5>Total : $ 600</h5>
          </div>
        </div>
      </div>
    </li>
    <li class="nav-item wish-nav" v-if="false">
      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
        <img src="@/assets/img/icon/wish.svg" alt="img" />
      </a>
      <div class="wishes-list dropdown-menu dropdown-menu-right">
        <div class="wish-content">
          <ul>
            <li>
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link to="/pages/course-details">
                      <img alt="Img" src="@/assets/img/course/course-04.jpg" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link to="/pages/course-details"
                        >Aprende Angular...</router-link
                      >
                    </h6>
                    <p>Por Dave Franco</p>
                    <h5>$200 <span>$99.00</span></h5>
                    <div class="remove-btn">
                      <a href="#" class="btn">Añadir a la cesta</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link to="/pages/course-details">
                      <img alt="Img" src="@/assets/img/course/course-14.jpg" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link to="/pages/course-details"
                        >Construya una realidad responsiva...</router-link
                      >
                    </h6>
                    <p>Jenis R.</p>
                    <h5>$200 <span>$99.00</span></h5>
                    <div class="remove-btn">
                      <a href="#" class="btn">Añadir a la cesta</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="media">
                <div class="d-flex media-wide">
                  <div class="avatar">
                    <router-link to="/pages/course-details">
                      <img alt="Img" src="@/assets/img/course/course-15.jpg" />
                    </router-link>
                  </div>
                  <div class="media-body">
                    <h6>
                      <router-link to="/pages/course-details"
                        >Desarrolladores de C# doble...</router-link
                      >
                    </h6>
                    <p>Jesse Stevens</p>
                    <h5>$200 <span>$99.00</span></h5>
                    <div class="remove-btn">
                      <a href="#" class="btn">Eliminar</a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li class="nav-item noti-nav">
      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
        <i class="fas fa-bell"></i>
      </a>
      <div class="notifications dropdown-menu dropdown-menu-right">
        <div class="topnav-dropdown-header">
          <span class="notification-title"
            >Notificaciones
            <select>
              <option>Todo</option>
              <option>No leído</option>
            </select>
          </span>
          <a href="javascript:void(0)" class="clear-noti"
            >Marcar todo como leído<i class="fa-solid fa-circle-check"></i
          ></a>
        </div>
        <div class="noti-content">
          <ul class="notification-list">
            <li class="notification-message">
              <div class="media d-flex">
                <div>
                  <router-link to="/pages/notifications" class="avatar">
                    <img
                      class="avatar-img"
                      alt="Img"
                      src="@/assets/img/user/user1.jpg"
                    />
                  </router-link>
                </div>
                <div class="media-body">
                  <h6>
                    <router-link to="/pages/notifications"
                      >Lex Murphy solicitó <span>acceso a</span> la jerarquía
                      del árbol de directorios de UNIX
                    </router-link>
                  </h6>
                  <button class="btn btn-accept">Aceptar</button>
                  <button class="btn btn-reject">Rechazar</button>
                  <p>Hoy a las 9:42 AM</p>
                </div>
              </div>
            </li>
            <li class="notification-message">
              <div class="media d-flex">
                <div>
                  <router-link to="/pages/notifications" class="avatar">
                    <img
                      class="avatar-img"
                      alt="Img"
                      src="@/assets/img/user/user2.jpg"
                    />
                  </router-link>
                </div>
                <div class="media-body">
                  <h6>
                    <router-link to="/pages/notifications"
                      >Ray Arnold dejó 6 comentarios en el informe de
                      cumplimiento de SOC2 de Isla Nublar
                    </router-link>
                  </h6>
                  <p>Ayer a las 23:42 h</p>
                </div>
              </div>
            </li>
            <li class="notification-message">
              <div class="media d-flex">
                <div>
                  <router-link to="/pages/notifications" class="avatar">
                    <img
                      class="avatar-img"
                      alt="Img"
                      src="@/assets/img/user/user3.jpg"
                    />
                  </router-link>
                </div>
                <div class="media-body">
                  <h6>
                    <router-link to="/pages/notifications"
                      >Dennis Nedry <span>comentó sobre</span> el informe de
                      cumplimiento de SOC2 de Isla Nublar</router-link
                    >
                  </h6>
                  <p class="noti-details">
                    “Oh, terminé de depurar los teléfonos, pero el sistema se
                    está compilando durante dieciocho minutos, o veinte. Por lo
                    tanto, algunos sistemas menores pueden funcionar y apagarse
                    por un tiempo”.
                  </p>
                  <p>Ayer a las 5:42 p. m.</p>
                </div>
              </div>
            </li>
            <li class="notification-message">
              <div class="media d-flex">
                <div>
                  <router-link to="/pages/notifications" class="avatar">
                    <img
                      class="avatar-img"
                      alt="Img"
                      src="@/assets/img/user/user1.jpg"
                    />
                  </router-link>
                </div>
                <div class="media-body">
                  <h6>
                    <router-link to="/pages/notifications"
                      >John Hammond <span>creó</span> el informe de cumplimiento
                      de SOC2 de Isla Nublar
                    </router-link>
                  </h6>
                  <p>El miércoles pasado a las 11:15 a. m.</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </li>
    <li class="nav-item user-nav">
      <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
        <span class="user-img">
          <img src="@/assets/img/logo/avatar_papo.png" alt="Img" />
          <span class="status online"></span>
        </span>
      </a>
      <div
        class="users dropdown-menu dropdown-menu-right"
        data-popper-placement="bottom-end"
      >
        <div class="user-header">
          <div class="avatar avatar-sm">
            <img
              src="@/assets/img/logo/avatar_papo.png"
              alt="User Image"
              class="avatar-img rounded-circle"
            />
          </div>
          <div class="user-text">
            <h6>{{ auth.user?.name }}</h6>
            <p class="text-muted mb-0">
              {{
                auth.user?.tipo_usuario == 1 ? "Super Admin" : "Administrador"
              }}
            </p>
          </div>
        </div>
        <router-link
          class="dropdown-item"
          to="/student/setting-edit-profile"
          v-if="false"
          ><i class="feather-user me-1"></i> Profile</router-link
        >
        <router-link
          v-if="false"
          class="dropdown-item"
          to="/student/setting-student-subscription"
          ><i class="feather-star me-1"></i> Subscription</router-link
        >
        <div class="dropdown-item night-mode" v-if="false">
          <span><i class="feather-moon me-1"></i> Night Mode </span>
          <div class="form-check form-switch check-on m-0">
            <input class="form-check-input" type="checkbox" id="night-mode" />
          </div>
        </div>
        <router-link class="dropdown-item" to="/"
          ><i class="feather-log-out me-1"></i> Cerrar Sesion</router-link
        >
      </div>
    </li>
  </ul>
</template>
<script>
import { ref, onMounted } from "vue";

import { useAuth } from "@/stores";

export default {
  data() {
    return {
      src: require("@/assets/img/user/avatar.png"),
    };
  },
  created() {
    this.auth = useAuth();
  },
  methods: {
    cerrarSesion() {
      if (confirm("¿Está seguro de cerrar sesión?")) this.auth.userLogout();
    },
  },
  setup() {
    const darkModeToggle = ref(null);
    const lightModeToggle = ref(null);

    // Function to enable dark mode
    function enableDarkMode() {
      document.documentElement.setAttribute("class", "light dark");
      darkModeToggle.value.classList.remove("activate");
      lightModeToggle.value.classList.add("activate");
      localStorage.setItem("darkMode", "enabled");
    }

    // Function to disable dark mode
    function disableDarkMode() {
      /* document.documentElement.setAttribute("class", "light");
      lightModeToggle.value.classList.remove("activate");
      darkModeToggle.value.classList.add("activate");
      localStorage.removeItem("darkMode"); */
    }

    // Check the current mode on page load
    onMounted(() => {
      const darkMode = localStorage.getItem("darkMode");
      if (darkMode === "enabled") {
        enableDarkMode();
      } else {
        disableDarkMode();
      }
    });

    return {
      darkModeToggle,
      lightModeToggle,
      enableDarkMode,
      disableDarkMode,
    };
  },
};
</script>
