<template>
  <layouts-instructorborder></layouts-instructorborder>
  <instructorgrid2></instructorgrid2>
  <!-- Page Wrapper -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <instructor-filter />

          <div class="row">
            <div
              class="col-lg-4 col-md-6 d-flex"
              v-for="instructor in paginatedInstructors"
              :key="instructor.id"
            >
              <div class="instructor-box flex-fill">
                <div class="instructor-img">
                  <div>
                    <img
                      class="img-fluid"
                      alt="Instructor"
                      :src="
                        instructor.imagen ||
                        require('@/assets/img/user/avatar.png')
                      "
                    />
                  </div>
                  <a href="javascript:;" class="rating-count">
                    <i class="fa-regular fa-heart"></i>
                  </a>
                </div>
                <div class="instructor-content">
                  <h5>
                    <router-link :to="`/register-step-one/${instructor.id}`">{{
                      instructor.nombres + " " + instructor.apellidos
                    }}</router-link>
                  </h5>
                  <h6>Instructor</h6>
                  <div class="instructor-info">
                    <div class="rating-img d-flex align-items-center">
                      <img
                        src="@/assets/img/icon/icon-01.svg"
                        class="me-1"
                        alt=""
                      />
                      <p>{{ instructor.pais }}</p>
                    </div>
                    <p>{{ instructor.profesion }}</p>
                  </div>
                  <div class="instructor-badge">
                    <span class="web-badge me-1">{{
                      instructor.biografia
                    }}</span>
                  </div>
                  <div class="rating">
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star filled me-1"></i>
                    <i class="fas fa-star me-1"></i>
                    <span class="d-inline-block average-rating">
                      <span>4.0</span> (15)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Pagination
            :totalPages="totalPages"
            v-model:currentPage="currentPage"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- /Page Wrapper -->

  <layouts1></layouts1>
</template>

<script>
import Pagination from "@/components/lms-pagination.vue";
import InstructorService from "@/service/InstructorService";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      instructorlist: [],
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.instructorlist.length / this.itemsPerPage);
    },
    paginatedInstructors() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.instructorlist.slice(start, end);
    },
  },
  created() {
    this.instructorService = new InstructorService();
  },
  mounted() {
    this.obtenerInstructores();
  },
  methods: {
    obtenerInstructores() {
      this.instructorService.getInstructor().then((response) => {
        if (!response.success) {
          return;
        }
        this.instructorlist = response.instructores;
      });
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
