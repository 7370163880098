import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class CategoriaService {
  // listar categorias
  async obtenerCategorias() {
    const categorias = await fetchWrapper.get(`${ruta}/categorias`);
    return categorias;
  }

  // Guardar categoria
  async guardarCategoria(categoria) {
    return await fetchWrapper.post(`${ruta}/categorias`, categoria);
  }

  // Actualizar categoria
  async actualizarCategoria(categoria) {
    const categoriaActualizada = await fetchWrapper.put(
      `${ruta}/categorias/` + categoria.id,
      categoria
    );
    return categoriaActualizada;
  }

  // Eliminar categoria
  async eliminarCategoria(id) {
    return await fetchWrapper.delete(`${ruta}/categorias/${id}`);
  }
}
