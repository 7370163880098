<template>
  <!-- Experienced Course Five -->
  <section class="experienced-course-five">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6" data-aos="fade-down">
          <div class="experienced-five-group">
            <div class="instructor-vector-left">
              <img src="@/assets/img/bg/instructor-vector-left.svg" alt="" />
            </div>
            <div class="developer-five-list">
              <ul>
                <li class="column-img" v-for="(profile, index) in profile" :key="index">
                  <div class="developer-profile-five">
                    <div class="developer-image">
                      <img
                        :src="require(`@/assets/img/profiles/${profile.imageSrc}`)"
                        :alt="profile.altText"
                      />
                    </div>
                    <div class="profile-five-ovelay">
                      <h5>{{ profile.name }}</h5>
                      <p>{{ profile.expertise }}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="instructor-vector-right">
              <img src="@/assets/img/bg/instructor-vector-right.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-6" data-aos="fade-down">
          <div class="experienced-five-sub">
            <div class="header-five-title header-five-title-inner">
              <h2 class="ex-five-title">Instructor de curso experimentado</h2>
            </div>
            <div class="career-five-content">
              <p class="ex-five-content">
                Obtenga una certificación, domine las habilidades tecnológicas modernas y suba de nivel en su carrera:
                ya sea que esté comenzando o sea un profesional experimentado. 95% Obtén la certificación, maestro
                habilidades tecnológicas modernas y mejorar tu carrera, ya sea que estés comenzando
                o un profesional experimentado. El 95% de los estudiantes de eLearning reportan nuestro contenido práctico
                ayudó directamente a sus carreras.
              </p>
            </div>
            <router-link to="/instructor/instructor-list" class="learn-more-five"
              >Empezar a aprender</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Experienced Course Five -->

  <!-- Share your knowledge -->
  <section class="share-knowledge-five">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-12" data-aos="fade-down">
          <div class="section-five-sub">
            <div class="header-five-title">
              <h2>¿Quieres compartir tus conocimientos? Únase a nosotros como mentor</h2>
            </div>
            <div class="career-five-content">
              <p>
                El vídeo de alta definición es un vídeo de mayor resolución y calidad que
                definición estándar Si bien no existe un significado estandarizado para
                Alta definición, generalmente cualquier vídeo mientras no exista un estándar.
                es decir, para alta definición, generalmente cualquier vídeo.
              </p>
            </div>
            <div class="knowledge-list-five">
              <ul>
                <li>
                  <div class="knowledge-list-group">
                    <img src="@/assets/img/icon/award-new.svg" alt="" />
                    <p>Mejores cursos</p>
                  </div>
                </li>
                <li class="mb-0">
                  <div class="knowledge-list-group">
                    <img src="@/assets/img/icon/award-new.svg" alt="" />
                    <p>Mejores cursos</p>
                  </div>
                </li>
              </ul>
            </div>
            <!-- Start Teaching Today -->
            <router-link to="/course/course-list" class="learn-more-five"
              >Comience a enseñar hoy</router-link
            >
            <!-- /Start Teaching Today -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" data-aos="fade-down">
          <div class="joing-count-five text-center">
            <img src="@/assets/img/joing-us-skill.png" alt="" />
            <div class="joing-count-five-one course-count">
              <h3 class="joing-count-number">
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="1"
                    :delay="3"
                    :endAmount="55"
                    :duration="5"
                    :autoinit="true" /></span
                >K<span>+</span>
              </h3>
              <p class="joing-count-text">Cursos</p>
            </div>
            <div class="joing-count-five-two course-count">
              <h3 class="joing-count-number">
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="1"
                    :delay="3"
                    :endAmount="50"
                    :duration="5"
                    :autoinit="true" /></span
                >K
              </h3>
              <p class="joing-count-text">Apreciación</p>
            </div>
            <div class="joing-count-five-three course-count">
              <h3 class="joing-count-number">
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="10"
                    :delay="3"
                    :endAmount="100"
                    :duration="5"
                    :autoinit="true"
                /></span>
              </h3>
              <p class="joing-count-text">Países</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Share your knowledge -->

  <!-- Achieve your Goals -->
  <section class="goals-section-five">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-x-4 col-lg-3 col-md-12 col-sm-12" data-aos="fade-down">
          <div class="header-five-title mb-0">
            <h2 class="mb-0">Logra tus Metas con DreamsLMS</h2>
          </div>
        </div>
        <div class="col-x-8 col-lg-9 col-md-12 col-sm-12">
          <div class="row text-center align-items-center">
            <!-- col -->
            <div class="col-lg-3 col-sm-3" data-aos="fade-down">
              <div class="goals-count-five goals-five-one">
                <div class="goals-content-five course-count ms-0">
                  <h4>
                    <span class="counterUp"
                      ><vue3-autocounter
                        class="counter"
                        ref="counter"
                        :startAmount="10"
                        :delay="3"
                        :endAmount="145"
                        :duration="5"
                        :autoinit="true"
                    /></span>
                  </h4>
                  <p class="mb-0">Tutores expertos</p>
                </div>
              </div>
            </div>
            <!--/ col -->

            <!-- col -->
            <div class="col-lg-3 col-sm-3" data-aos="fade-down">
              <div class="goals-count-five goals-five-two">
                <div class="goals-content-five course-count ms-0">
                  <h4>
                    <span class="counterUp"
                      ><vue3-autocounter
                        class="counter"
                        ref="counter"
                        :startAmount="1000"
                        :delay="3"
                        :endAmount="23495"
                        :duration="5"
                        :autoinit="true"
                    /></span>
                  </h4>
                  <p class="mb-0">Cursos certificados</p>
                </div>
              </div>
            </div>
            <!--/ col -->

            <!-- col -->
            <div class="col-lg-3 col-sm-3" data-aos="fade-down">
              <div class="goals-count-five goals-five-three">
                <div class="goals-content-five course-count ms-0">
                  <h4>
                    <span class="counterUp"
                      ><vue3-autocounter
                        class="counter"
                        ref="counter"
                        :startAmount="1"
                        :delay="3"
                        :endAmount="20"
                        :duration="5"
                        :autoinit="true" /></span
                    >+
                  </h4>
                  <p class="mb-0">estudiantes en línea</p>
                </div>
              </div>
            </div>
            <!--/ col -->

            <!-- col -->
            <div class="col-lg-3 col-sm-3" data-aos="fade-down">
              <div class="goals-count-five goals-five-four goals-five-last">
                <div class="goals-content-five course-count ms-0">
                  <h4>
                    <span class="counterUp"
                      ><vue3-autocounter
                        class="counter"
                        ref="counter"
                        :startAmount="1000"
                        :delay="3"
                        :endAmount="58370"
                        :duration="5"
                        :autoinit="true"
                    /></span>
                  </h4>
                  <p class="mb-0"> Cursos Online</p>
                </div>
              </div>
            </div>
            <!--/ col -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Achieve your Goals -->

  <!-- Share your knowledge -->
  <section class="transform-section-five">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6 col-sm-12" data-aos="fade-down">
          <div class="joing-count-five">
            <img src="@/assets/img/education.png" alt="" />
            <div class="transform-count-five-one course-count">
              <h3 class="joing-count-number">
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="10"
                    :delay="3"
                    :endAmount="100"
                    :duration="5"
                    :autoinit="true"
                /></span>
              </h3>
              <p class="joing-count-text">Países</p>
            </div>
            <div class="transform-count-five-two course-count">
              <h3 class="joing-count-number">
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="1"
                    :delay="3"
                    :endAmount="55"
                    :duration="5"
                    :autoinit="true" /></span
                >K<span>+</span>
              </h3>
              <p class="joing-count-text">Cursos</p>
            </div>
            <div class="transform-count-five-three course-count">
              <h3 class="joing-count-number">
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="1"
                    :delay="3"
                    :endAmount="50"
                    :duration="5"
                    :autoinit="true" /></span
                >K<span>+</span>
              </h3>
              <p class="joing-count-text">Apreciación</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12" data-aos="fade-down">
          <div class="transform-access-content">
            <div class="header-five-title">
              <h2>Transformar el acceso a la educación</h2>
            </div>
            <div class="career-five-content">
              <p class="mb-0">
                Cree una cuenta para recibir nuestro boletín informativo, recomendaciones de cursos y
                promociones Crea una cuenta para recibir nuestra newsletter, por supuesto.
                recomendaciones y promociones. Crea una cuenta para recibir nuestra
                newsletter, recomendaciones de cursos y promociones.
              </p>
            </div>
            <div class="knowledge-list-five">
              <ul>
                <li>
                  <div class="knowledge-list-group">
                    <img src="@/assets/img/icon/award-new.svg" alt="" />
                    <p>Gestión de cursos galardonada</p>
                  </div>
                </li>
                <li>
                  <div class="knowledge-list-group">
                    <img src="@/assets/img/icon/award-new.svg" alt="" />
                    <p>Aprende cualquier cosa desde cualquier lugar en cualquier momento</p>
                  </div>
                </li>
                <li class="mb-0">
                  <div class="knowledge-list-group">
                    <img src="@/assets/img/icon/award-new.svg" alt="" />
                    <p>Certificación para un sólido desarrollo de tu carrera</p>
                  </div>
                </li>
              </ul>
            </div>
            <!-- Start Teaching Today -->
            <router-link to="/instructor/instructor-course" class="learn-more-five"
              >Comience a enseñar hoy</router-link
            >
            <!-- /Start Teaching Today -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Share your knowledge -->
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import profile from "@/assets/json/profile.json";
export default {
  data() {
    return {
      profile: profile,
    };
  },

  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
