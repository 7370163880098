<template>
  <div class="error-page">
      <div class="error-box">
        <div class="error-logo">
          <router-link to="/home/">
            <img src="@/assets/img/logo/logo_letras.svg" class="img-fluid" alt="Logo" />
          </router-link>
        </div>
        <div class="error-box-img">
          <img src="@/assets/img/error-papo.png" alt="" class="img-fluid" />
        </div>
        <h3 class="h2 mb-3">Oh No! Error 404</h3>
        <p class="h4 font-weight-normal">
          No se pudo encontrar la página solicitada. ¡Que la fuerza te acompañe!
        </p>
        <router-link to="/home/" class="btn btn-primary">Volver a Inicio</router-link>
      </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
