<template>
  <!-- Acheive you Goals  -->
  <section class="home-three-goals">
    <div class="container">
      <div class="row align-items-center">
        <!-- Col -->
        <div class="col-xl-3 col-lg-12 col-md-12" data-aos="fade-down">
          <div class="acheive-goals-main">
            <h2>Logra tus metas con DreamsLMS</h2>
          </div>
        </div>
        <!-- /Col -->

        <!-- Col -->
        <div class="col-xl-3 col-lg-4 col-md-4 col-12" data-aos="fade-down">
          <div class="acheive-goals">
            <div class="acheive-elips-one">
              <img src="@/assets/img/icon-three/ellipse-1.svg" alt="" />
            </div>
            <div class="acheive-goals-content text-center course-count ms-0">
              <h4>
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="2000"
                    :delay="3"
                    :endAmount="253085"
                    :duration="5"
                    :autoinit="true"
                /></span>
              </h4>
              <p>Estudiantes matriculados en todo el mundo</p>
            </div>
          </div>
        </div>
        <!-- /Col -->

        <!-- Col -->
        <div class="col-xl-3 col-lg-4 col-md-4 col-12" data-aos="fade-down">
          <div class="acheive-goals">
            <div class="acheive-elips-two">
              <img src="@/assets/img/icon-three/ellipse-2.svg" alt="" />
            </div>
            <div class="acheive-goals-content text-center course-count ms-0">
              <h4>
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="100"
                    :delay="3"
                    :endAmount="1205"
                    :duration="5"
                    :autoinit="true"
                /></span>
              </h4>
              <p>Total de cursos en nuestra plataforma</p>
            </div>
          </div>
        </div>
        <!-- /Col -->

        <!-- Col -->
        <div class="col-xl-3 col-lg-4 col-md-4 col-12" data-aos="fade-down">
          <div class="acheive-goals">
            <div class="acheive-elips-three">
              <img src="@/assets/img/icon-three/ellipse-3.svg" alt="" />
            </div>
            <div class="acheive-goals-content text-center course-count ms-0">
              <h4>
                <span class="counterUp"
                  ><vue3-autocounter
                    class="counter"
                    ref="counter"
                    :startAmount="10"
                    :delay="3"
                    :endAmount="56"
                    :duration="5"
                    :autoinit="true"
                /></span>
              </h4>
              <p>Países de estudiantes</p>
            </div>
          </div>
        </div>
        <!-- /Col -->
      </div>
    </div>
  </section>
  <!-- /Acheive you Goals  -->

  <!-- Accelerate cloud -->
  <section class="accelerate-cloud-three">
    <div class="container">
      <div class="shapes-three-right">
        <img class="accelerate-one" src="@/assets/img/bg/shape-1.png" alt="" />
        <img class="accelerate-two" src="@/assets/img/bg/pattern-03.png" alt="" />
      </div>
      <div class="shapes-three-left">
        <img class="accelerate-three" src="@/assets/img/bg/pattern-02.png" alt="" />
        <img class="accelerate-four" src="@/assets/img/bg/shape-2.png" alt="" />
        <img class="accelerate-five" src="@/assets/img/bg/pattern-04.png" alt="" />
      </div>
      <div class="home-three-head section-header-title" data-aos="fade-up">
        <div class="row align-items-center d-flex justify-content-between">
          <div class="col-lg-6 col-md-12">
            <div class="home-three-head">
              <h2 class="text-white">
                Acelere el éxito de la nube con aprendizaje práctico a escala
              </h2>
            </div>
          </div>
          <div class="col-lg-6 col-md-12">
            <p class="accelerate-three-business">
              El 96% de los clientes de eLearning for Business ven mejores resultados en seis
              meses Ya sea que sea un equipo de 10 o 10 000 personas, la fluidez en la nube comienza más rápido
              aquí
            </p>
          </div>
        </div>
      </div>

      <!-- Award Winning -->
      <div class="award-one">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12" data-aos="fade-right">
            <div class="award-three-images-one">
              <img
                class="img-fluid"
                src="@/assets/img/students/award-01.png"
                alt="image-banner"
                title="image-banner"
              />
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6 col-12">
            <div class="award-three-content-one">
              <div class="award-list-info" data-aos="fade-up">
                <div class="award-win-icon">
                  <img src="@/assets/img/icon-three/award.svg" alt="certified" />
                </div>
                <div class="award-list-content">
                  <h2>Gestión de cursos galardonada</h2>
                  <p>
                    El cliente es muy importante, el cliente será seguido por el cliente. que dolor
                    tarea de dibujos animados de masa de levadura Una carrera necesita baloncesto
                    ahora en el hospital Es conveniente para alguien que está sano por diabetes e incluso enfermo por alguna enfermedad.
                    Chocolate
                  </p>
                </div>
              </div>

              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-1.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-1.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
              <div class="award-list mb-0 d-flex align-items-center" data-aos="fade-up">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-1.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Award Winning -->

      <!-- Learn Anything -->
      <div class="learn-anything">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="award-three-content-two">
              <div class="award-list-info">
                <div class="award-win-icon">
                  <img
                    class="img-fluid"
                    src="@/assets/img/icon-three/time.svg"
                    alt="certified"
                  />
                </div>
                <div class="award-list-content">
                  <h2>Aprende cualquier cosa desde cualquier lugar en cualquier momento</h2>
                  <p>
                    El cliente es muy importante, el cliente será seguido por el cliente.
                    tarea de dibujos animados de masa de levadura Una carrera necesita baloncesto
                    ahora en el hospital Es conveniente para alguien que está sano por diabetes e incluso enfermo por alguna enfermedad.
                    Chocolate
                  </p>
                </div>
              </div>

              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-2.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-2.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-2.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6 col-12" data-aos="fade-right">
            <div class="award-three-images-two">
              <img
                class="img-fluid"
                src="@/assets/img/students/learn-anything.png"
                alt="image-banner"
                title="image-banner"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- /Learn Anything -->

      <!-- development-carrer-->
      <div class="development-carrer">
        <div class="row align-items-center">
          <div class="col-xl-6 col-lg-6 col-md-6 col-12" data-aos="fade-right">
            <div class="award-three-images-three">
              <img
                class="img-fluid"
                src="@/assets/img/students/certification.png"
                alt="image-banner"
                title="image-banner"
              />
            </div>
          </div>

          <div class="col-xl-6 col-lg-6 col-md-6 col-12" data-aos="fade-up">
            <div class="award-three-content-one">
              <div class="award-list-info">
                <div class="award-win-icon">
                  <img
                    class="img-fluid"
                    src="@/assets/img/icon-three/winning.svg"
                    alt="certified"
                  />
                </div>
                <div class="award-list-content">
                  <h2>Certificación para un sólido desarrollo de tu carrera</h2>
                  <p>
                    El cliente es muy importante, el cliente será seguido por el cliente.
                    tarea de dibujos animados de masa de levadura Una carrera necesita baloncesto
                    ahora en el hospital Es conveniente para alguien que está sano por diabetes e incluso enfermo por alguna enfermedad.
                    Chocolate
                  </p>
                </div>
              </div>

              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-3.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
              <div class="award-list d-flex align-items-center">
                <span class="award-icon">
                  <img
                    src="@/assets/img/icon-three/check-round-3.svg"
                    alt=""
                    class="img-fluid"
                  />
                </span>
                <p>El cliente es muy importante, el cliente será seguido por el cliente.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /development-carrer -->
    </div>
  </section>
  <!-- Accelerate cloud -->

  <!-- Trending Courses  Three -->
  <section class="home-three-trending">
    <div class="container">
      <div class="row">
        <div class="home-three-head section-header-title" data-aos="fade-up">
          <div class="row align-items-center d-flex justify-content-between">
            <div class="col-lg-12">
              <h2>Cursos más populares</h2>
            </div>
          </div>
        </div>
        <Carousel
          class="home-three-trending-course"
          :settings="settings"
          :breakpoints="breakpoints"
        >
          <Slide v-for="item in acheivegoal" :key="item.courseTitle">
            <div class="trending-three-item">
              <div class="trending-content-top text-start" :class="item.bgClass">
                <div class="course-title-one">
                  <router-link to="/course/course-details">{{ item.courseTitle }}</router-link>
                </div>
                <div class="trending-three-text">
                  <router-link to="/course/course-details">
                    <h3 class="title instructor-text">{{ item.courseName }}</h3>
                    <p>{{ item.courseDescription }}</p>
                  </router-link>
                </div>
                <div
                  class="trending-counts-info d-flex align-items-center justify-content-between"
                >
                  <div class="trending-three-counts d-flex align-items-center">
                    <img
                      :src="require(`@/assets/img/icon-three/${item.studentIcon}`)"
                      alt="student-icon"
                    />
                    <p>{{ item.studentCount }} Estudiantes</p>
                  </div>
                  <div class="price-three-time d-inline-flex align-items-center">
                    <i class="fa-regular fa-clock me-2"></i>
                    <span>{{ item.duration }}</span>
                  </div>
                </div>
              </div>
              <div class="trending-content-footer">
                <div
                  class="trending-price-group d-flex align-items-center justify-content-between"
                >
                  <div class="trending-group-view">
                    <div class="trending-price-three">
                      <h3>{{ item.originalPrice }}</h3>
                      <span>{{ item.discountPrice }}</span>
                    </div>
                  </div>
                  <div class="trending-footer-img">
                    <router-link to="/course/course-details">
                      <img
                        :src="require(`@/assets/img/profiles/${item.instructorImage}`)"
                        alt="img"
                      />
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
      <!-- /Favourite Course -->
    </div>
  </section>
  <!-- Feature Course -->

  <section class="gallery-three">
    <div class="container">
      <div class="home-three-head section-header-title" data-aos="fade-up">
        <div class="row align-items-center d-flex justify-content-between">
          <div class="col-lg-6 col-sm-12">
            <h2>Cursos impartidos por instructores reales.</h2>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="see-all">
              <router-link to="/instructor/instructor-list"
                >Ver todo<span class="see-all-icon"
                  ><i class="fas fa-arrow-right"></i></span
              ></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="courses-gallery-three">
        <div class="row">
          <!-- Col -->
          <div class="col-lg-5 col-md-5">
            <div class="row">
              <div class="col-lg-7 col-md-7">
                <div class="gallery-three-img-item">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-1.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5">
                <div class="gallery-three-img-item">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-2.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-md-5">
                <div class="gallery-three-img-item mb-0">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-3.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-7">
                <div class="gallery-three-img-item mb-0">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-4.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-lg-3 col-md-3">
            <div class="gallery-three-img-item mb-0">
              <div class="content-three-main">
                <div class="gallery-img">
                  <img src="@/assets/img/gallery/gallery-5.jpg" alt="Instructor" />
                </div>
                <div class="content-three-overlay">
                  <div class="content-three-text">
                    <div>
                      <h6>Patricia</h6>
                      <p>Node Js</p>
                    </div>
                    <div>
                      <router-link to="/instructor/instructor-list" class="content-three-arrows">
                        <span><i class="fas fa-arrow-right"></i></span>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->

          <!-- Col -->
          <div class="col-lg-4 col-md-4">
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="gallery-three-img-item">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-6.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="gallery-three-img-item mb-0">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-7.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="gallery-three-img-item mb-0">
                  <div class="content-three-main">
                    <div class="gallery-img">
                      <img src="@/assets/img/gallery/gallery-8.jpg" alt="Instructor" />
                    </div>
                    <div class="content-three-overlay">
                      <div class="content-three-text">
                        <div>
                          <h6>Patricia</h6>
                          <p>Node Js</p>
                        </div>
                        <div>
                          <router-link to="/instructor/instructor-list" class="content-three-arrows">
                            <span><i class="fas fa-arrow-right"></i></span>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Col -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import acheivegoal from "@/assets/json/acheivegoal.json";
import { Carousel, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      acheivegoal: acheivegoal,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        // 700px and up
        700: {
          itemsToShow: 2,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 4.2,
          snapAlign: "start",
        },
      },
    };
  },

  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
