<template>
  <layouts-login></layouts-login>
  <bloglist></bloglist>
  <!-- Curso -->
  <section class="course-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <!-- Publicación del Blog -->
          <div class="blog" v-for="post in blogPosts" :key="post.id">
            <div class="blog-image">
              <router-link to="/blog/blog-details">
                <img
                  class="img-fluid"
                  :src="require(`@/assets/img/blog/${post.image}`)"
                  alt="Imagen de la Publicación"
              /></router-link>
            </div>
            <div class="blog-info clearfix">
              <div class="post-left">
                <ul>
                  <li>
                    <div class="post-author">
                      <router-link to="/instructor/instructor-profile"
                        ><img
                          :src="
                            require(`@/assets/img/user/${post.authorImage}`)
                          "
                          alt="Autor de la Publicación"
                        />
                        <span>{{ post.author }}</span></router-link
                      >
                    </div>
                  </li>
                  <li>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icon/icon-22.svg"
                      alt=""
                    />{{ post.date }}
                  </li>
                  <li>
                    <img
                      class="img-fluid"
                      src="@/assets/img/icon/icon-23.svg"
                      alt=""
                    />{{ post.category }}
                  </li>
                </ul>
              </div>
            </div>
            <h3 class="blog-title">
              <router-link to="/blog/blog-details">{{ post.title }}</router-link>
            </h3>
            <div class="blog-content blog-read">
              <p>{{ post.content }}</p>
              <router-link to="/blog/blog-details" class="read-more btn btn-primary"
                >Leer Más</router-link
              >
            </div>
          </div>
          <!-- /Publicación del Blog -->

          <pagination></pagination>
        </div>

        <blog-sidebar></blog-sidebar>
      </div>
    </div>
  </section>
  <!-- /Curso -->

  <layouts1></layouts1>
</template>
<script>
export default {
  data() {
    return {
      blogPosts: [
        {
          id: 1,
          image: "blog-05.jpg",
          authorImage: "user.jpg",
          author: "Ruby Perrin",
          date: "20 de abril, 2023",
          category: "Programación, Diseño Web",
          title: "Aprende Desarrollo de Aplicaciones Web de Expertos",
          content:
            "Introducción al Desarrollo Web: Conceptos básicos de Internet y navegadores, introducción a HTML, CSS y JavaScript. Diseño Web Responsive: Uso de frameworks como Bootstrap y principios de diseño adaptable. Frontend Avanzado: Interactividad con JavaScript y frameworks modernos como Vue.js o React. Backend y Bases de Datos: Introducción a servidores con Node.js y conexión con bases de datos (MySQL, MongoDB). Pruebas y Despliegue: Herramientas para pruebas de aplicaciones y despliegue en servidores y plataformas en la nube. Proyecto Final: Desarrollo de una aplicación web completa con las tecnologías aprendidas.",
            
        },
        {
          id: 2,
          image: "blog-06.jpg",
          authorImage: "user1.jpg",
          author: "Jenis R.",
          date: "20 de mayo, 2023",
          category: "Programación, Cursos",
          title: "Amplía tus Oportunidades de Carrera con Python",
          content:
            "Introducción a la Programación: Conceptos básicos, sintaxis y estructura de Python. Manejo de Datos: Tipos de datos, listas, diccionarios y conjuntos. Control de Flujo: Condicionales, bucles y excepciones. Programación Orientada a Objetos: Clases, herencia y polimorfismo en Python. Bibliotecas Esenciales: Uso de módulos como NumPy, pandas y matplotlib. Desarrollo de Proyectos: Automatización de tareas, análisis de datos y creación de aplicaciones. Proyecto Final: Aplicación práctica utilizando Python para resolver problemas del mundo real.",
        },
        {
          id: 3,
          image: "blog-07.jpg",
          authorImage: "user3.jpg",
          author: "Rolands R.",
          date: "20 de junio, 2023",
          category: "Programación, Diseño Web",
          title: "Guía Completa de Carrera en Programación PHP",
          content:
            "Introducción a PHP: Conceptos básicos, configuración del entorno y primeros scripts. Estructuras de Control: Condicionales, bucles y manejo de errores en PHP. Manejo de Formularios: Validación y procesamiento de datos del usuario. Conexión a Bases de Datos: Uso de MySQL con PHP para almacenar y recuperar datos. Programación Orientada a Objetos: Clases, objetos y principios básicos en PHP. Desarrollo de Aplicaciones Web: Integración de PHP con HTML, CSS y JavaScript. Seguridad en PHP: Prevención de vulnerabilidades comunes como SQL Injection y XSS. Proyecto Final: Creación de una aplicación web funcional utilizando PHP y bases de datos.",
        },
      ],
    };
  },
};
</script>
