<template>
  <ul class="user-list">
    <li class="user-list-item chat-user-list">
      <a href="javascript:void(0);">
        <div class="avatar avatar-online">
          <img src="@/assets/img/user/user11.jpg" class="rounded-circle" alt="image" />
        </div>
        <div class="users-list-body">
          <div>
            <h5>Horace Keene</h5>
            <p>Have you called them?</p>
          </div>
          <div class="last-chat-time">
            <small class="text-muted">Just Now</small>
            <div class="chat-pin">
              <span class="count-message">5</span>
            </div>
          </div>
        </div>
      </a>
      <div class="chat-hover ms-1">
        <div class="chat-action-col">
          <span class="d-flex" data-bs-toggle="dropdown">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
            <span class="dropdown-item"
              ><span><i class="bx bx-archive-in"></i></span>Archive Chat
            </span>
            <span class="dropdown-item"
              ><span><i class="bx bx-volume-mute"></i></span>Mute Notification</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-trash"></i></span>Delete Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-pin"></i></span>Pin Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-check-square"></i></span>Mark as Read</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-block"></i></span>Block</span
            >
          </div>
        </div>
      </div>
    </li>
    <li class="user-list-item chat-user-list">
      <a href="javascript:void(0);">
        <div>
          <div class="avatar avatar-online">
            <img src="@/assets/img/user/user4.jpg" class="rounded-circle" alt="image" />
          </div>
        </div>
        <div class="users-list-body">
          <div>
            <h5>Hollis Tran</h5>
            <p><i class="bx bx-video me-1"></i>Video</p>
          </div>
          <div class="last-chat-time">
            <small class="text-muted">Yesterday</small>
            <div class="chat-pin">
              <i class="fa-solid fa-check"></i>
            </div>
          </div>
        </div>
      </a>
      <div class="chat-hover ms-1">
        <div class="chat-action-col">
          <span class="d-flex" data-bs-toggle="dropdown">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
            <span class="dropdown-item"
              ><span><i class="bx bx-archive-in"></i></span>Archive Chat
            </span>
            <span class="dropdown-item"
              ><span><i class="bx bx-volume-mute"></i></span>Mute Notification</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-trash"></i></span>Delete Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-pin"></i></span>Pin Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-check-square"></i></span>Mark as Unread</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-block"></i></span>Block</span
            >
          </div>
        </div>
      </div>
    </li>
    <li class="user-list-item chat-user-list">
      <a href="javascript:void(0);">
        <div class="avatar">
          <img src="@/assets/img/user/user.jpg" class="rounded-circle" alt="image" />
        </div>
        <div class="users-list-body">
          <div>
            <h5>James Albert</h5>
            <p><i class="bx bx-file me-1"></i>Project Tools.doc</p>
          </div>
          <div class="last-chat-time">
            <small class="text-muted">10:20 PM</small>
          </div>
        </div>
      </a>
      <div class="chat-hover ms-1">
        <div class="chat-action-col">
          <span class="d-flex" data-bs-toggle="dropdown">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
            <span class="dropdown-item"
              ><span><i class="bx bx-archive-in"></i></span>Archive Chat
            </span>
            <span class="dropdown-item"
              ><span><i class="bx bx-volume-mute"></i></span>Mute Notification</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-trash"></i></span>Delete Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-pin"></i></span>Pin Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-check-square"></i></span>Mark as Unread</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-block"></i></span>Block</span
            >
          </div>
        </div>
      </div>
    </li>
    <li class="user-list-item chat-user-list">
      <a href="javascript:void(0);">
        <div>
          <div class="avatar avatar-online">
            <img src="@/assets/img/user/user6.jpg" class="rounded-circle" alt="image" />
          </div>
        </div>
        <div class="users-list-body">
          <div>
            <h5>Debra Jones</h5>
            <p><i class="bx bx-microphone me-1"></i>Audio</p>
          </div>
          <div class="last-chat-time">
            <small class="text-muted">12:30 PM</small>
            <div class="chat-pin">
              <i class="fa-solid fa-check-double check"></i>
            </div>
          </div>
        </div>
      </a>
      <div class="chat-hover ms-1">
        <div class="chat-action-col">
          <span class="d-flex" data-bs-toggle="dropdown">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
            <span class="dropdown-item"
              ><span><i class="bx bx-archive-in"></i></span>Archive Chat
            </span>
            <span class="dropdown-item"
              ><span><i class="bx bx-volume-mute"></i></span>Mute Notification</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-trash"></i></span>Delete Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-pin"></i></span>Pin Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-check-square"></i></span>Mark as Unread</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-block"></i></span>Block</span
            >
          </div>
        </div>
      </div>
    </li>
    <li class="user-list-item chat-user-list">
      <a href="javascript:void(0);">
        <div>
          <div class="avatar">
            <img src="@/assets/img/user/user1.jpg" class="rounded-circle" alt="image" />
          </div>
        </div>
        <div class="users-list-body">
          <div>
            <h5>Dina Brown</h5>
            <p>Have you called them?</p>
          </div>
          <div class="last-chat-time">
            <small class="text-muted">Yesterday</small>
            <div class="chat-pin">
              <i class="fa-solid fa-microphone-slash"></i>
            </div>
          </div>
        </div>
      </a>
      <div class="chat-hover ms-1">
        <div class="chat-action-col">
          <span class="d-flex" data-bs-toggle="dropdown">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
            <span class="dropdown-item"
              ><span><i class="bx bx-archive-in"></i></span>Archive Chat
            </span>
            <span class="dropdown-item"
              ><span><i class="bx bx-volume-mute"></i></span>Mute Notification</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-trash"></i></span>Delete Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-pin"></i></span>Pin Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-check-square"></i></span>Mark as Unread</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-block"></i></span>Block</span
            >
          </div>
        </div>
      </div>
    </li>
    <li class="user-list-item chat-user-list">
      <a href="javascript:void(0);">
        <div>
          <div class="avatar avatar-online">
            <img src="@/assets/img/user/user13.jpg" class="rounded-circle" alt="image" />
          </div>
        </div>
        <div class="users-list-body">
          <div>
            <h5>Judy Mercer</h5>
            <p class="missed-call-col">
              <i class="bx bx-phone-incoming me-1"></i>Missed Call
            </p>
          </div>
          <div class="last-chat-time">
            <small class="text-muted">25/July/23</small>
          </div>
        </div>
      </a>
      <div class="chat-hover ms-1">
        <div class="chat-action-col">
          <span class="d-flex" data-bs-toggle="dropdown">
            <i class="fa-solid fa-ellipsis-vertical"></i>
          </span>
          <div class="dropdown-menu chat-drop-menu dropdown-menu-end">
            <span class="dropdown-item"
              ><span><i class="bx bx-archive-in"></i></span>Archive Chat
            </span>
            <span class="dropdown-item"
              ><span><i class="bx bx-volume-mute"></i></span>Mute Notification</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-trash"></i></span>Delete Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-pin"></i></span>Pin Chat</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-check-square"></i></span>Mark as Unread</span
            >
            <span class="dropdown-item"
              ><span><i class="bx bx-block"></i></span>Block</span
            >
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
