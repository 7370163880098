<template>
  <student-header></student-header>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <student-sidebar></student-sidebar>
        <!-- /Sidebar -->

        <!-- Student Dashboard -->
        <div class="col-xl-9 col-lg-9">
          <!-- Dashboard Grid -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Inscritos</h5>
                  <h2>12</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Activos</h5>
                  <h2>03</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Completados</h5>
                  <h2>13</h2>
                </div>
              </div>
            </div>
          </div>
          <!-- /Dashboard Grid -->

          <div class="dashboard-title">
            <h4>Cursos Recientemente Inscritos</h4>
          </div>
          <div class="row">
            <!-- Course Grid -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-02.jpg"
                      />
                    </router-link>
                    <div class="price">
                      <h3>Bs.80 <span>Bs.99.00</span></h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user2.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4><router-link to="/instructor/instructor-profile">Carlos</router-link></h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Wordpress para Principiantes - Domina Wordpress Rápidamente</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>12+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>70hr 30min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>5.0</span> (20)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Course Grid -->

            <!-- Course Grid -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-03.jpg"
                      />
                    </router-link>
                    <div class="price combo">
                      <h3>GRATIS</h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user5.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4><router-link to="/instructor/instructor-profile">Jenny</router-link></h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Sketch de A a Z (2022): Conviértete en diseñador de aplicaciones</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>10+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>40hr 10min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star me-1"></i>
                      <i class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>3.0</span> (18)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Course Grid -->

            <!-- Course Grid -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-04.jpg"
                      />
                    </router-link>
                    <div class="price">
                      <h3>Bs.65 <span>Bs.70.00</span></h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user4.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4><router-link to="/instructor/instructor-profile">Nicole Brown</router-link></h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Aprende los Fundamentos de Angular desde el nivel básico hasta el avanzado</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>15+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>80hr 40min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>4.0</span> (25)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Course Grid -->
          </div>
          <div class="row mt-4">
            <div class="col">
              <nav aria-label="Page navigation">
                <ul class="pagination justify-content-center">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">Anterior</a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">3</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">Siguiente</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
        <!-- /Student Dashboard -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: 'Tablero del Estudiante',
      text: 'Administra tus cursos y tu progreso',
      text1: 'Mira los cursos que has tomado y tu desempeño general.',
    };
  },
};
</script>

<style scoped>
/* Puedes agregar estilos aquí */
</style>
