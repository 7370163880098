<template>
  <layouts-loginborder></layouts-loginborder>
  <notifications-breadcrumb></notifications-breadcrumb>
  <!-- Notificación -->
  <section class="course-content">
    <div class="container">
      <div class="title-sec">
        <div class="row">
          <div class="col-sm-6 col-lg-5">
            <h2>Notificaciones</h2>
            <p>
              Mejora la forma en que trabajas, descubre una nueva herramienta y
              elimina las complicaciones de una vez por todas.
            </p>
          </div>
          <div class="col-sm-6 col-lg-7">
            <div class="text-end">
              <a href="javascript:;" class="btn btn-mark"
                >Marcar todo como leído</a
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Lista de Notificaciones -->
      <div class="notify-sec">
        <div class="row">
          <div class="col-md-12">
            <h5>Hoy</h5>
            <div class="notify-item">
              <div class="row align-items-center">
                <div class="col-md-9">
                  <div class="notify-content">
                    <router-link to="/instructor/instructor-profile">
                      <img
                        class="avatar-img semirounded-circle"
                        src="@/assets/img/user/user2.jpg"
                        alt="Imagen de Usuario"
                      />
                    </router-link>
                    <div class="notify-detail">
                      <h6>
                        <router-link to="/instructor/instructor-profile"
                          >Rolands R </router-link
                        ><span>Hoy a las 9:42 AM</span>
                      </h6>
                      <p>
                        Es un hecho conocido que el lector se distraerá con el
                        contenido legible de una página al ver su diseño. El
                        objetivo de usar Lorem Ipsum es que tiene una
                        distribución de letras más o menos normal, en lugar de
                        usar "Contenido aquí".
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-md-end">
                    <a href="javascript:;" class="btn">Marcar como leído</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="notify-item">
              <div class="row align-items-center">
                <div class="col-md-9">
                  <div class="notify-content">
                    <router-link to="/instructor/instructor-profile">
                      <img
                        class="avatar-img semirounded-circle"
                        src="@/assets/img/user/user1.jpg"
                        alt="Imagen de Usuario"
                      />
                    </router-link>
                    <div class="notify-detail">
                      <h6>
                        <router-link to="/instructor/instructor-profile"
                          >Jenis R. </router-link
                        ><span>Hoy a las 10:08 AM</span>
                      </h6>
                      <p>
                        Es un hecho conocido que el lector se distraerá con el
                        contenido legible de una página al ver su diseño. El
                        objetivo de usar Lorem Ipsum es que tiene una
                        distribución de letras más o menos normal, en lugar de
                        usar "Contenido aquí".
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-md-end">
                    <a href="javascript:;" class="btn">Marcar como leído</a>
                  </div>
                </div>
              </div>
            </div>

            <h5>Ayer</h5>
            <div class="notify-item">
              <div class="row align-items-center">
                <div class="col-md-9">
                  <div class="notify-content">
                    <router-link to="/instructor/instructor-profile">
                      <img
                        class="avatar-img semirounded-circle"
                        src="@/assets/img/user/user3.jpg"
                        alt="Imagen de Usuario"
                      />
                    </router-link>
                    <div class="notify-detail">
                      <h6>
                        <router-link to="/instructor/instructor-profile"
                          >Jesse Stevens</router-link
                        >
                        <span>Ayer a las 9:42 AM</span>
                      </h6>
                      <p>
                        Es un hecho conocido que el lector se distraerá con el
                        contenido legible de una página al ver su diseño. El
                        objetivo de usar Lorem Ipsum es que tiene una
                        distribución de letras más o menos normal, en lugar de
                        usar "Contenido aquí".
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-md-end">
                    <a href="javascript:;" class="btn">Marcar como leído</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="notify-item">
              <div class="row align-items-center">
                <div class="col-md-9">
                  <div class="notify-content">
                    <router-link to="/instructor/instructor-profile">
                      <img
                        class="avatar-img semirounded-circle"
                        src="@/assets/img/user/user7.jpg"
                        alt="Imagen de Usuario"
                      />
                    </router-link>
                    <div class="notify-detail">
                      <h6>
                        <router-link to="/instructor/instructor-profile"
                          >Rolands R</router-link
                        >
                        <span>Ayer a las 5:40 AM</span>
                      </h6>
                      <p>
                        Es un hecho conocido que el lector se distraerá con el
                        contenido legible de una página al ver su diseño. El
                        objetivo de usar Lorem Ipsum es que tiene una
                        distribución de letras más o menos normal, en lugar de
                        usar "Contenido aquí".
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-md-end">
                    <a href="javascript:;" class="btn">Marcar como leído</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="notify-item">
              <div class="row align-items-center">
                <div class="col-md-9">
                  <div class="notify-content">
                    <router-link to="/instructor/instructor-profile">
                      <img
                        class="avatar-img semirounded-circle"
                        src="@/assets/img/user/user11.jpg"
                        alt="Imagen de Usuario"
                      />
                    </router-link>
                    <div class="notify-detail">
                      <h6>
                        <router-link to="/instructor/instructor-profile"
                          >John Michael</router-link
                        >
                        <span>Ayer a las 7:40 AM</span>
                      </h6>
                      <p>
                        Es un hecho conocido que el lector se distraerá con el
                        contenido legible de una página al ver su diseño. El
                        objetivo de usar Lorem Ipsum es que tiene una
                        distribución de letras más o menos normal, en lugar de
                        usar "Contenido aquí".
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="text-md-end">
                    <a href="javascript:;" class="btn">Marcar como leído</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /Lista de Notificaciones -->
    </div>
  </section>
  <!-- /Notificación -->

  <layouts1></layouts1>
</template>
