<template>
  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID de Pedido</th>
          <th>Nombre del Curso</th>
          <th>Fecha</th>
          <th>Precio</th>
          <th>Estado</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><a href="javascript:void(0);" class="tab-title">#2643</a></td>
          <td>
            <router-link
              to="/course/course-details"
              class="tab-title title-course"
              >Construye sitios web responsivos del mundo real con HTML5 y
              CSS3</router-link
            >
          </td>
          <td>24 de marzo de 2024</td>
          <td>$34</td>
          <td>En Espera</td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bxs-download"></i
            ></a>
          </td>
        </tr>
        <tr>
          <td><a href="javascript:void(0);" class="tab-title">#2644</a></td>
          <td>
            <router-link
              to="/course/course-details"
              class="tab-title title-course"
              >Esquema de A a Z (2024): Conviértete en diseñador de
              aplicaciones</router-link
            >
          </td>
          <td>26 de marzo de 2024</td>
          <td>$40</td>
          <td>En Espera</td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bxs-download"></i
            ></a>
          </td>
        </tr>
        <tr>
          <td><a href="javascript:void(0);" class="tab-title">#2645</a></td>
          <td>
            <router-link
              to="/course/course-details"
              class="tab-title title-course"
              >Aprender Fundamentos de Angular Guía para
              Principiantes</router-link
            >
          </td>
          <td>12 de abril de 2024</td>
          <td>$25</td>
          <td>Completado</td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bxs-download"></i
            ></a>
          </td>
        </tr>
        <tr>
          <td><a href="javascript:void(0);" class="tab-title">#2646</a></td>
          <td>
            <router-link
              to="/course/course-details"
              class="tab-title title-course"
              >Construye sitios web responsivos del mundo real con HTML5 y
              CSS3</router-link
            >
          </td>
          <td>16 de abril de 2024</td>
          <td>$35</td>
          <td>En Espera</td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bxs-download"></i
            ></a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
