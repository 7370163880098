<template>
  <student-header></student-header>

  <student-breadcrumb :title="title" :text="text" :text1="text1" />

  <!-- Contenido de la Página -->
  <div class="page-content fondoSettings">
    <div class="container">
      <div class="row">
        <!-- barra lateral -->
        <student-sidebar></student-sidebar>
        <!-- /Barra lateral -->

        <!-- Perfil del estudiante -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details mb-0">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Mis Certificados</h3>
              </div>
              <!-- Datos de usuario -->
              <DataTable
                :value="certificados"
                responsiveLayout="scroll"
                :paginator="true"
                :rows="5"
                :paginatorLeft="true"
                :paginatorRight="true"
              >
                <Column field="id" header="ID"></Column>
                <Column field="name" header="Nombre"></Column>
                <Column field="description" header="Descripción"></Column>
                <Column field="start_date" header="Fecha de Inicio"></Column>
                <Column
                  field="end_date"
                  header="Fecha de Finalización"
                ></Column>
                <Column field="created_at" header="Fecha de Registro"></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <!-- /Perfil del estudiante -->
      </div>
    </div>
  </div>
  <!-- /Contenido de la Página -->

  <layouts1></layouts1>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from 'primevue/column';
export default {
  components: {
    DataTable,
    Column
  },
  data() {
    return {
      title: "Mi Perfil",
      text: "Inicio",
      text1: "Mi Perfil",
      certificados: [],
    };
  },
};
</script>
