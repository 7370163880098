<template>
  <layouts-loginborder></layouts-loginborder>
  <job-category></job-category>

  <!-- Page Wrapper -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="title-sec">
            <h2>Listado de Categorías</h2>
          </div>
        </div>
        <div class="col-lg-6 d-flex justify-content-end">
          <div class="title-sec">
            <button type="button" class="btn btn-primary" @click="abrirModal()">
              <i class="fas fa-plus"></i>
              Agregar Categoría
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="table-responsive">
            <table
              class="table table-bordered table-hover table-sm"
              width="100%"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NOMBRE</th>
                  <th>DESCRIPCIÓN</th>
                  <th>ESTADO</th>
                  <th>ACCIONES</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="categoria in categorias" :key="categoria.id">
                  <td>{{ categoria.id }}</td>
                  <td>{{ categoria.nombre }}</td>
                  <td>{{ categoria.descripcion }}</td>
                  <td
                    :class="{
                      'text-success': categoria.estado == 1,
                      'text-danger': categoria.estado == 0,
                    }"
                  >
                    {{ categoria.estado == 1 ? "Activo" : "Inactivo" }}
                  </td>
                  <td class="text-center">
                    <button
                      class="btn btn-warning mr-1"
                      @click="abrirModal(categoria)"
                    >
                      <i class="fas fa-edit"></i>
                      Editar
                    </button>
                    <button
                      class="btn btn-danger"
                      @click="eliminarCategoria(categoria.id)"
                    >
                      <i class="fas fa-trash"></i>
                      Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div
        v-if="mostrarModal"
        class="modal fade show"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modalAgregarCategoria"
        aria-hidden="true"
        style="display: block; background-color: rgba(0, 0, 0, 0.5)"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">
                {{ esEdicion ? "Editar Categoría" : "Agregar Categoría" }}
              </h5>
              <button
                type="button"
                class="close"
                @click="cerrarModal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="guardarCategoria">
                <div class="form-group">
                  <label for="nombre">Nombre</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nombre"
                    v-model="categoriaForm.nombre"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="descripcion">Descripción</label>
                  <input
                    type="text"
                    class="form-control"
                    id="descripcion"
                    v-model="categoriaForm.descripcion"
                  />
                </div>
                <div class="form-group">
                  <label for="estado">Estado</label>
                  <select
                    class="form-control"
                    id="estado"
                    v-model="categoriaForm.estado"
                  >
                    <option value="1">Activo</option>
                    <option value="0">Inactivo</option>
                  </select>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="cerrarModal"
                  >
                    Cerrar
                  </button>
                  <button type="submit" class="btn btn-primary">
                    {{ esEdicion ? "Actualizar" : "Guardar" }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>

<script>
import CategoriaService from "@/service/CategoriaService";

export default {
  data() {
    return {
      categorias: [],
      categoriaForm: {
        id: null,
        nombre: "",
        descripcion: "",
        estado: "1",
      },
      mostrarModal: false,
      esEdicion: false,
    };
  },
  created() {
    this.categoria = new CategoriaService();
  },
  mounted() {
    this.obtenerCategorias();
  },
  methods: {
    abrirModal(categoria = null) {
      if (categoria) {
        // Modo edición
        this.esEdicion = true;
        this.categoriaForm = { ...categoria }; // Cargar datos de la categoría seleccionada
      } else {
        // Modo agregar
        this.esEdicion = false;
        this.categoriaForm = {
          id: null,
          nombre: "",
          descripcion: "",
          estado: "1",
        };
      }
      this.mostrarModal = true;
    },
    cerrarModal() {
      this.mostrarModal = false;
    },
    guardarCategoria() {
      if (this.esEdicion) {
        // Actualizar categoría
        let data = {
          id: this.categoriaForm.id,
          nombre: this.categoriaForm.nombre,
          descripcion: this.categoriaForm.descripcion,
          estado: this.categoriaForm.estado,
        };
        console.log(data);
        this.categoria.actualizarCategoria(data).then((response) => {
          console.log(response);
          if (response.success == false) {
            this.$message.error(response.message);
            return;
          } else {
            this.$message.success(response.message);
            this.obtenerCategorias();
            this.cerrarModal();
          }
        });
      } else {
        // Guardar nueva categoría
        let data = {
          nombre: this.categoriaForm.nombre,
          descripcion: this.categoriaForm.descripcion,
          estado: this.categoriaForm.estado,
        };
        this.categoria.guardarCategoria(data).then((response) => {
          if (response.success == false) {
            this.$message.error(response.message);
            return;
          } else {
            this.$message.success(response.message);
            this.obtenerCategorias();
            this.cerrarModal();
          }
        });
      }
    },
    obtenerCategorias() {
      this.categoria.obtenerCategorias().then((response) => {
        this.categorias = response.categorias || [];
      });
    },
    eliminarCategoria(id) {
      //LANZO UN ALERT PARA CONFIRMAR SI DESEA ELIMINAR
      if (!confirm("¿Está seguro de eliminar la categoría?")) {
        return;
      }
      this.categoria.eliminarCategoria(id).then((response) => {
        if (response.success == false) {
          this.$message.error(response.message);
          return;
        }
        this.$message.success(response.message);
        this.obtenerCategorias();
      });
    },
  },
};
</script>
