<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Configuración del Instructor -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-details">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Configuraciones</h3>
                <p>
                  Tienes control total para gestionar la configuración de tu
                  cuenta
                </p>
              </div>
              <instructor-settings-sidebar></instructor-settings-sidebar>
              <form action="/instructor-settings">
                <div class="course-group profile-upload-group mb-0 d-flex">
                  <div
                    class="course-group-img profile-edit-field d-flex align-items-center"
                  >
                    <div
                      to="/student/student-profile"
                      class="profile-pic"
                      ><img :src="src" alt="Img" class="img-fluid"
                    /></div>
                    <div class="profile-upload-head">
                      <h4>
                        <router-link to="/instructor/instructor-profile"
                          >Tu avatar</router-link
                        >
                      </h4>
                      <p>PNG o JPG no mayor a 800px de ancho y alto</p>
                      <div class="new-employee-field">
                        <div class="d-flex align-items-center mt-2">
                          <FileUpload
                            mode="basic"
                            @select="onFileSelect"
                            customUpload
                            auto
                            choose-icon="pi pi-images"
                            chooseLabel=" "
                            severity="secondary"
                            class="p-button-outlined"
                          />
                          <div class="img-delete">
                            <a href="#" class="delete-icon"
                              ><i class="bx bx-trash"></i
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="checkout-form settings-wrap">
                  <div class="edit-profile-info">
                    <h5>Detalles Personales</h5>
                    <p>Edita tu información personal</p>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Nombre</label>
                        <input
                          type="text"
                          class="form-control"
                          value="Nombre"
                          v-model="usuario.nombres"
                        />
                        <Message class="text-danger" v-if="errors.nombres" severity="error" variant="simple">{{
                          errors.nombres[0]
                        }}</Message>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Apellido</label>
                        <input
                          type="text"
                          class="form-control"
                          value="Apellidos"
                          v-model="usuario.apellidos"
                        />
                        <Message class="text-danger" v-if="errors.apellidos" severity="error" variant="simple">{{
                          errors.apellidos[0]
                        }}</Message>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Email</label>
                        <input
                          type="text"
                          class="form-control"
                          value="studentdemo"
                          v-model="user.email"
                        />
                        <Message class="text-danger" v-if="errors.email" severity="error" variant="simple">{{
                          errors.email[0]
                        }}</Message>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Número de Teléfono</label>
                        <input
                          type="text"
                          class="form-control"
                          value="Numero de telefono"
                          v-model="usuario.telefono"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <span>Pais</span>
                        <AutoComplete
                          v-model="pais"
                          dropdown
                          :suggestions="paises"
                          placeholder="Seleccione un pais"
                          optionLabel="nombre"
                          optionValue="id"
                          @complete="searchPaises"
                        />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="input-block">
                        <label class="form-label">Direccion</label>
                        <input
                          type="text"
                          class="form-control"
                          value="Direccion"
                          v-model="usuario.direccion"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="input-block">
                        <label class="form-label">Biografía</label>
                        <Textarea
                          v-model="usuario.biografia"
                          rows="5"
                          cols="30"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <Button
                        class="btn btn-primary"
                        @click="actualizarInstructor"
                        label="Actualizar Perfil"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!-- /Configuración del Instructor -->
      </div>
    </div>
  </div>
  <layouts1></layouts1>
</template>
<script>
import UserService from "@/service/UserService";
import InstructorService from "@/service/InstructorService";

import AutoComplete from "primevue/autocomplete";
import Button from "primevue/button";
import Textarea from "primevue/textarea";
import FileUpload from "primevue/fileupload";
import Message from 'primevue/message';

export default {
  components: {
    AutoComplete,
    Button,
    Textarea,
    FileUpload,
    Message,
  },
  data() {
    return {
      title: "Configuraciones",
      text: "Inicio",
      text1: "Editar Perfil",
      user: {},
      usuario: {},
      paises: [],
      pais: null,
      src: require("@/assets/img/user/avatar.png"),
      selectedFile: null,
      errors: {},
    };
  },
  created() {
    this.userService = new UserService();
    this.instructorService = new InstructorService();
  },
  mounted() {
    this.obtenerUsuario();
  },
  methods: {
    obtenerUsuario() {
      this.userService.getUser().then((response) => {
        if(!response.success) {
          return;
        }
        this.user = response.user;
        this.usuario = response.usuario;
        this.pais = response.usuario.pais ?? null;
        this.src = response.usuario.foto
        ? response.usuario.foto
        : require("@/assets/img/user/avatar.png");
      });
    },
    searchPaises(event) {
      const query = {
        dato: event.query,
      };
      this.instructorService.buscarPais(query).then((response) => {
        this.paises = response.pais;
      });
    },
    actualizarInstructor() {
      const data_enviar = {
        ...this.usuario,
        pais_id: this.pais ? this.pais.id : null,
        email: this.user.email,
        password: this.user.password,
      };
      this.uploadImage();
      this.instructorService.updateInstructor(data_enviar).then((response) => {
        console.log(response);
        if (response.errors) {
          this.$message.error(response.message);
          this.errors = response.errors;
          return;
        }
        else if (response.success) {
          this.$message.success(response.message);
          this.obtenerUsuario();
        } else {
          this.$message.error(response.message);
        }
      });
    },
    onFileSelect(event) {
      const file = event.files[0];
      const reader = new FileReader();
      reader.onload = async (e) => {
        this.src = e.target.result;
        this.selectedFile = reader.result.split(',')[1]; // Obtiene la cadena Base64 sin el encabezado
      };
      reader.readAsDataURL(file);
    },
    uploadImage() {
      if (!this.selectedFile) {
        return;
      }
      const payload = {
        image: this.selectedFile,
        name : this.usuario.nombres ?? 'instructor',
      };
      this.instructorService.uploadImage(payload).then((response) => {
        if (!response.success) {
          this.$message.error(response.message);
        } 
      });
    },
  },
};
</script>
<style>
.text-danger {
    color: red;
}
</style>