<template>
  <layouts-instructorborder></layouts-instructorborder>

  <student-breadcrumb :title="title" :text="text" :text1="text1" />

  <!-- Page Content -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- sidebar -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Sidebar -->

        <!-- Student Dashboard -->
        <div class="col-xl-9 col-lg-9">
          <!-- Dashboard Grid -->
          <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Inscritos</h5>
                  <h2>13</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Activos</h5>
                  <h2>08</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Cursos Completados</h5>
                  <h2>06</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Total de Estudiantes</h5>
                  <h2>5</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Total de Cursos</h5>
                  <h2>11</h2>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="card dash-info flex-fill">
                <div class="card-body">
                  <h5>Ganancias Totales</h5>
                  <h2>Bs.486</h2>
                </div>
              </div>
            </div>
          </div>

          <!-- /Dashboard Grid -->

          <div class="instructor-course-table" v-if="false">
            <div class="dashboard-title">
              <h4>Cursos Recientemente Creados</h4>
            </div>
            <div class="table-responsive custom-table">
              <!-- Usuarios Referidos -->
              <table class="table table-nowrap mb-0">
                <thead>
                  <tr>
                    <th>Cursos</th>
                    <th>Inscritos</th>
                    <th>Estado</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div class="table-course-detail">
                        <router-link
                          to="/course/course-details"
                          class="course-table-img"
                        >
                          <span
                            ><img
                              src="@/assets/img/instructor/instructor-table-01.jpg"
                              alt="Img"
                          /></span>
                          Curso Completo de HTML, CSS y Javascript
                        </router-link>
                      </div>
                    </td>
                    <td>0</td>
                    <td>Publicado</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="table-course-detail">
                        <router-link
                          to="/course/course-details"
                          class="course-table-img"
                        >
                          <span
                            ><img
                              src="@/assets/img/instructor/instructor-table-05.jpg"
                              alt="Img"
                          /></span>
                          Curso Completo de Desarrollador Web Fullstack
                        </router-link>
                      </div>
                    </td>
                    <td>2</td>
                    <td>Publicado</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="table-course-detail">
                        <router-link
                          to="/course/course-details"
                          class="course-table-img"
                        >
                          <span
                            ><img
                              src="@/assets/img/instructor/instructor-table-02.jpg"
                              alt="Img"
                          /></span>
                          Fundamentos de Ciencia de Datos y Bootcamp Avanzado
                        </router-link>
                      </div>
                    </td>
                    <td>2</td>
                    <td>Publicado</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="table-course-detail">
                        <router-link
                          to="/course/course-details"
                          class="course-table-img"
                        >
                          <span
                            ><img
                              src="@/assets/img/instructor/instructor-table-03.jpg"
                              alt="Img"
                          /></span>
                          Domina Microservicios con Spring Boot y Spring Cloud
                        </router-link>
                      </div>
                    </td>
                    <td>1</td>
                    <td>Publicado</td>
                  </tr>
                  <tr>
                    <td>
                      <div class="table-course-detail">
                        <router-link
                          to="/course/course-details"
                          class="course-table-img"
                        >
                          <span
                            ><img
                              src="@/assets/img/instructor/instructor-table-04.jpg"
                              alt="Img"
                          /></span>
                          Información Sobre el Grado en Diseño UI/UX
                        </router-link>
                      </div>
                    </td>
                    <td>3</td>
                    <td>Publicado</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="dashboard-title" v-if="false">
            <h4>Cursos Recientemente Inscritos</h4>
          </div>
          <div class="row" v-if="false">
            <!-- Rejilla de Cursos -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-02.jpg"
                      />
                    </router-link>
                    <div class="price">
                      <h3>Bs.80 <span>Bs.99.00</span></h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user2.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4>
                            <router-link to="/instructor/instructor-profile"
                              >Carlos Alvares</router-link
                            >
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Wordpress para Principiantes - Domina Wordpress
                        Rápidamente</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>12+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>70hr 30min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>5.0</span> (20)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Rejilla de Cursos -->

            <!-- Rejilla de Cursos -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-03.jpg"
                      />
                    </router-link>
                    <div class="price combo">
                      <h3>GRATIS</h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user5.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4>
                            <router-link to="/instructor/instructor-profile"
                              >Jenny</router-link
                            >
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Sketch de A a Z (2022): Conviértete en un diseñador de
                        aplicaciones</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>10+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>40hr 10min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star me-1"></i>
                      <i class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>3.0</span> (18)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Rejilla de Cursos -->

            <!-- Rejilla de Cursos -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-04.jpg"
                      />
                    </router-link>
                    <div class="price">
                      <h3>Bs.65 <span>Bs.70.00</span></h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user4.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4>
                            <router-link to="/instructor/instructor-profile"
                              >Nicole Brown</router-link
                            >
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Aprende los Fundamentos de Angular Desde Principiante
                        hasta Nivel Avanzado</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>15+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>80hr 40min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>4.0</span> (10)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Rejilla de Cursos -->

            <!-- Rejilla de Cursos -->
            <div class="col-xxl-4 col-md-6 d-flex">
              <div class="course-box flex-fill">
                <div class="product">
                  <div class="product-img">
                    <router-link to="/course/course-details">
                      <img
                        class="img-fluid"
                        alt="Img"
                        src="@/assets/img/course/course-05.jpg"
                      />
                    </router-link>
                    <div class="price combo">
                      <h3>GRATIS</h3>
                    </div>
                  </div>
                  <div class="product-content">
                    <div class="course-group d-flex">
                      <div class="course-group-img d-flex">
                        <router-link to="/instructor/instructor-profile"
                          ><img
                            src="@/assets/img/user/user3.jpg"
                            alt="Img"
                            class="img-fluid"
                        /></router-link>
                        <div class="course-name">
                          <h4>
                            <router-link to="/instructor/instructor-profile"
                              >John Smith</router-link
                            >
                          </h4>
                          <p>Instructor</p>
                        </div>
                      </div>
                      <div
                        class="course-share d-flex align-items-center justify-content-center"
                      >
                        <a href="#"><i class="fa-regular fa-heart"></i></a>
                      </div>
                    </div>
                    <h3 class="title instructor-text">
                      <router-link to="/course/course-details"
                        >Construye Sitios Web Responsivos del Mundo Real con
                        Crash Course</router-link
                      >
                    </h3>
                    <div class="course-info d-flex align-items-center">
                      <div class="rating-img d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                        <p>20+ Lecciones</p>
                      </div>
                      <div class="course-view d-flex align-items-center">
                        <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                        <p>90hr 20min</p>
                      </div>
                    </div>
                    <div class="rating mb-0">
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <i class="fas fa-star filled me-1"></i>
                      <span class="d-inline-block average-rating"
                        ><span>5.0</span> (25)</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Rejilla de Cursos -->
          </div>

          <div class="dash-pagination" v-if="false">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Pagina 1 de 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Student Dashboard -->
      </div>
    </div>
  </div>
  <!-- /Page Content -->

  <layouts1></layouts1>
</template>

<script>
export default {
  data() {
    return {
      title: "Panel de control",
      text: "Inicio",
      text1: "Panel de control",
    };
  },
};
</script>
