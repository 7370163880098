<template>
  <layouts-login></layouts-login>

  <bloggrid></bloggrid>

  <!-- Cuadrícula de Blog -->
  <section class="course-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-9 col-md-12">
          <div class="row">
            <div
              class="col-md-6 col-sm-12"
              v-for="blogPost in blogPosts"
              :key="blogPost.id"
            >
              <!-- Publicación del Blog -->
              <div class="blog grid-blog">
                <div class="blog-image">
                  <router-link to="/blog/blog-details">
                    <img
                      class="img-fluid"
                      :src="require(`@/assets/img/blog/${blogPost.image}`)"
                      alt="Imagen de la Publicación"
                    />
                  </router-link>
                </div>
                <div class="blog-grid-box">
                  <div class="blog-info clearfix">
                    <div class="post-left">
                      <ul>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-22.svg"
                            alt=""
                          />
                          {{ blogPost.date }}
                        </li>
                        <li>
                          <img
                            class="img-fluid"
                            src="@/assets/img/icon/icon-23.svg"
                            alt=""
                          />
                          {{ blogPost.category }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <h3 class="blog-title">
                    <router-link to="/blog/blog-details">{{ blogPost.title }}</router-link>
                  </h3>
                  <div class="blog-content blog-read">
                    <p>{{ blogPost.content }}</p>
                    <router-link to="/blog/blog-details" class="read-more btn btn-primary">
                      Leer Más
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- /Publicación del Blog -->
            </div>
          </div>

          <pagination></pagination>
        </div>

        <blog-sidebar></blog-sidebar>
      </div>
    </div>
  </section>
  <!-- /Cuadrícula de Blog -->

  <layouts1></layouts1>
</template>
<script>
export default {
  data() {
    return {
      blogPosts: [
        {
          id: 1,
          title: "Aprende Desarrollo de Aplicaciones Web de Expertos",
          image: "blog-08.jpg",
          date: "14 de junio, 2023",
          category: "Programación, Diseño Web",
          content:
            "El cliente debería estar muy contento, es un buen cliente. Hendrerit de la flota Pero la necesidad, antes y vulputar volutpat",
        },
        {
          id: 2,
          title: "Amplía tus Oportunidades de Carrera con Python",
          image: "blog-09.jpg",
          date: "24 de mayo, 2023",
          category: "Programación, Cursos",
          content:
            "El cliente debería estar muy contento, es un buen cliente. Hendrerit de la flota Pero la necesidad, antes y vulputar volutpat",
        },
        {
          id: 3,
          title: "Guía Completa de Carrera en Programación PHP",
          image: "blog-10.jpg",
          date: "14 de junio, 2023",
          category: "Programación, Diseño Web",
          content:
            "El cliente debería estar muy contento, es un buen cliente. Hendrerit de la flota Pero la necesidad, antes y vulputar volutpat",
        },
        {
          id: 4,
          title: "Guía de Contenido de Programación para Principiantes",
          image: "blog-11.jpg",
          date: "18 de junio, 2023",
          category: "Programación, Cursos",
          content:
            "El cliente debería estar muy contento, es un buen cliente. Hendrerit de la flota Pero la necesidad, antes y vulputar volutpat",
        },
        {
          id: 5,
          title: "El Curso Completo de JavaScript para Principiantes",
          image: "blog-12.jpg",
          date: "26 de junio, 2023",
          category: "Programación, Diseño Web",
          content:
            "El cliente debería estar muy contento, es un buen cliente. Hendrerit de la flota Pero la necesidad, antes y vulputar volutpat",
        },
        {
          id: 6,
          title: "Aprende Desarrollo de Aplicaciones Móviles de Expertos",
          image: "blog-13.jpg",
          date: "18 de junio, 2023",
          category: "Programación, Cursos",
          content:
            "El cliente debería estar muy contento, es un buen cliente. Hendrerit de la flota Pero la necesidad, antes y vulputar volutpat",
        },
      ],
    };
  },
};
</script>
