<template>
  <page-header></page-header>

  <cart-breadcrumb></cart-breadcrumb>
  <!-- Carrito -->
  <section class="course-content cart-widget">
    <div class="container">
      <div class="student-widget">
        <div class="student-widget-group">
          <div class="row">
            <div class="col-lg-12">
              <div class="cart-head">
                <h4>Tu carrito (03 artículos)</h4>
              </div>
              <div class="cart-group">
                <div class="row">
                  <div class="col-lg-12 col-md-12 d-flex">
                    <div class="course-box course-design list-course d-flex">
                      <div class="product">
                        <div class="product-img">
                          <router-link to="/course/course-details">
                            <img
                              class="img-fluid"
                              alt=""
                              src="@/assets/img/course/course-10.jpg"
                            />
                          </router-link>
                          <div class="price">
                            <h3 class="free-color">GRATIS</h3>
                          </div>
                        </div>
                        <div class="product-content">
                          <div class="head-course-title">
                            <h3 class="title">
                              <router-link to="/course/course-details"
                                >Información sobre el Grado en Diseño UI/UX</router-link
                              >
                            </h3>
                          </div>
                          <div
                            class="course-info d-flex align-items-center border-bottom-0 pb-0"
                          >
                            <div class="rating-img d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-01.svg" alt="" />
                              <p>12+ Lecciones</p>
                            </div>
                            <div class="course-view d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-02.svg" alt="" />
                              <p>9hr 30min</p>
                            </div>
                          </div>
                          <div class="rating">
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>4.0</span> (15)</span
                            >
                          </div>
                        </div>
                        <div class="cart-remove">
                          <a href="javascript:;" class="btn btn-primary"
                            >Eliminar</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 d-flex">
                    <div class="course-box course-design list-course d-flex">
                      <div class="product">
                        <div class="product-img">
                          <router-link to="/course/course-details">
                            <img
                              class="img-fluid"
                              alt=""
                              src="@/assets/img/course/course-11.jpg"
                            />
                          </router-link>
                          <div class="price">
                            <h3>Bs.300 <span>Bs.99.00</span></h3>
                          </div>
                        </div>
                        <div class="product-content">
                          <div class="head-course-title">
                            <h3 class="title">
                              <router-link to="/course/course-details"
                                >Wordpress para Principiantes - Domina Wordpress
                                Rápidamente</router-link
                              >
                            </h3>
                          </div>
                          <div
                            class="course-info d-flex align-items-center border-bottom-0 pb-0"
                          >
                            <div class="rating-img d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-01.svg" alt="" />
                              <p>10+ Lecciones</p>
                            </div>
                            <div class="course-view d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-02.svg" alt="" />
                              <p>7hr 20min</p>
                            </div>
                          </div>
                          <div class="rating">
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>4.2</span> (15)</span
                            >
                          </div>
                        </div>
                        <div class="cart-remove">
                          <a href="javascript:;" class="btn btn-primary"
                            >Eliminar</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 d-flex">
                    <div
                      class="course-box course-design list-course d-flex mb-0"
                    >
                      <div class="product">
                        <div class="product-img">
                          <router-link to="/course/course-details">
                            <img
                              class="img-fluid"
                              alt=""
                              src="@/assets/img/course/course-12.jpg"
                            />
                          </router-link>
                          <div class="price">
                            <h3>Bs.300 <span>Bs.99.00</span></h3>
                          </div>
                        </div>
                        <div class="product-content">
                          <div class="head-course-title">
                            <h3 class="title">
                              <router-link to="/course/course-details"
                                >Sketch de A a Z (2023): Conviértete en un diseñador de apps</router-link
                              >
                            </h3>
                          </div>
                          <div
                            class="course-info d-flex align-items-center border-bottom-0 pb-0"
                          >
                            <div class="rating-img d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-01.svg" alt="" />
                              <p>12+ Lecciones</p>
                            </div>
                            <div class="course-view d-flex align-items-center">
                              <img src="@/assets/img/icon/icon-02.svg" alt="" />
                              <p>9hr 30min</p>
                            </div>
                          </div>
                          <div class="rating">
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star filled me-1"></i>
                            <i class="fas fa-star me-1"></i>
                            <span class="d-inline-block average-rating"
                              ><span>4.0</span> (15)</span
                            >
                          </div>
                        </div>
                        <div class="cart-remove">
                          <a href="javascript:;" class="btn btn-primary"
                            >Eliminar</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-total">
                <div class="row">
                  <div class="col-lg-12 col-md-12">
                    <div class="cart-subtotal">
                      <p>Subtotal <span>Bs.600.00</span></p>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="check-outs">
                      <router-link to="/pages/checkout" class="btn btn-primary"
                        >Pagar</router-link
                      >
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <div class="continue-shop">
                      <router-link
                        to="/course/course-list"
                        class="btn btn-primary"
                        >Continuar Comprando</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Carrito -->

  <layouts1></layouts1>
</template>
