<template>
  <div class="row">
    <!-- Cuadrícula de Cursos -->
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                src="@/assets/img/course/fortigate.png"
              />
            </router-link>
            <div class="price">
              <h3> <span></span></h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img
                    src="@/assets/img/user/user2.jpg"
                    alt="Img"
                    class="img-fluid"
                  />
                </router-link>
                <div class="course-name">
                  <h4>
                    <router-link to="/instructor/instructor-profile">
                      Carlos Alvares
                    </router-link>
                  </h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">
                Curso de Fortinet2 - Preparación VCTA-DC 2024 de vmware
              </router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>12+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>70hr 30min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <span class="d-inline-block average-rating">
                <span>5.0</span> (20)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cuadrícula de Cursos -->

    <!-- Cuadrícula de Cursos -->
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                src="@/assets/img/course/dmarc.png"
              />
            </router-link>
            <div class="price combo">
              <h3></h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img
                    src="@/assets/img/user/user5.jpg"
                    alt="Img"
                    class="img-fluid"
                  />
                </router-link>
                <div class="course-name">
                  <h4>
                    <router-link to="/instructor/instructor-profile">
                      Ruben Jaldin
                    </router-link>
                  </h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">
                Configuración - DMARC Rápidamente
              </router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>10+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>40hr 10min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star me-1"></i>
              <i class="fas fa-star me-1"></i>
              <span class="d-inline-block average-rating">
                <span>3.0</span> (18)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cuadrícula de Cursos -->

    <!-- Cuadrícula de Cursos -->
   <!-- <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                src="@/assets/img/course/course-04.jpg"
              />
            </router-link>
            <div class="price">
              <h3>Bs.65 <span>Bs.70.00</span></h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img
                    src="@/assets/img/user/user4.jpg"
                    alt="Img"
                    class="img-fluid"
                  />
                </router-link>
                <div class="course-name">
                  <h4>
                    <router-link to="/instructor/instructor-profile">
                      Nicole Brown
                    </router-link>
                  </h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">
                Aprende los Fundamentos de Angular Desde Principiante hasta Nivel Avanzado
              </router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>15+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>80hr 40min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star me-1"></i>
              <span class="d-inline-block average-rating">
                <span>4.0</span> (10)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
   Cuadrícula de Cursos 
    Cuadrícula de Cursos 
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                src="@/assets/img/course/course-05.jpg"
              />
            </router-link>
            <div class="price combo">
              <h3>GRATIS</h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img
                    src="@/assets/img/user/user3.jpg"
                    alt="Img"
                    class="img-fluid"
                  />
                </router-link>
                <div class="course-name">
                  <h4>
                    <router-link to="/instructor/instructor-profile">
                      John Smith
                    </router-link>
                  </h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">
                Construye Sitios Web Responsivos del Mundo Real con Curso Intensivo
              </router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>12+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>70hr 30min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star me-1"></i>
              <span class="d-inline-block average-rating">
                <span>4.0</span> (15)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cuadrícula de Cursos -->

    <!-- Cuadrícula de Cursos -->
   <!-- <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                src="@/assets/img/course/course-07.jpg"
              />
            </router-link>
            <div class="price">
              <h3>Bs.70 <span>Bs.80.00</span></h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img
                    src="@/assets/img/user/user6.jpg"
                    alt="Img"
                    class="img-fluid"
                  />
                </router-link>
                <div class="course-name">
                  <h4>
                    <router-link to="/instructor/instructor-profile">
                      Stella Johnson
                    </router-link>
                  </h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">
                Aprende JavaScript y Express para Convertirte en Experto
              </router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>15+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>70hr 30min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star me-1"></i>
              <span class="d-inline-block average-rating">
                <span>4.6</span> (15)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cuadrícula de Cursos -->

    <!-- Cuadrícula de Cursos 
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                src="@/assets/img/course/course-08.jpg"
              />
            </router-link>
            <div class="price combo">
              <h3>GRATIS</h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img
                    src="@/assets/img/user/user1.jpg"
                    alt="Img"
                    class="img-fluid"
                  />
                </router-link>
                <div class="course-name">
                  <h4>
                    <router-link to="/instructor/instructor-profile">
                      Nicole Brown
                    </router-link>
                  </h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">
                Introducción a la Programación: Python y Java
              </router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>10+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>70hr 30min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <span class="d-inline-block average-rating">
                <span>5.0</span> (13)
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>-->
    <!-- /Cuadrícula de Cursos -->
  </div>
</template>

