<template>
  <!-- Inner Banner -->
  <div class="inner-banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="instructor-wrap border-bottom-0 m-0">
            <div class="about-instructor align-items-center">
              <div class="abt-instructor-img">
                <router-link to="/instructor/instructor-profile"
                  ><img
                    src="@/assets/img/user/user1.jpg"
                    alt="img"
                    class="img-fluid"
                /></router-link>
              </div>
              <div class="instructor-detail me-3">
                <h5>
                  <router-link to="/instructor/instructor-profile"
                    >Nicole Brown</router-link
                  >
                </h5>
                <p>Diseñadora UX/UI</p>
              </div>
              <div class="rating mb-0">
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <i class="fas fa-star filled me-1"></i>
                <span class="d-inline-block Average-rating"
                  ><span>4.5</span> (15)</span
                >
              </div>
            </div>
            <span class="web-badge mb-3">DESARROLLO WEB</span>
          </div>
          <h2>El curso completo para desarrolladores web 2.0</h2>
          <p>
            ¡Aprenda desarrollo web creando 25 sitios web y aplicaciones móviles
            usando HTML, CSS, Javascript, PHP, Python, MySQL y más!
          </p>
          <div
            class="course-info d-flex align-items-center border-bottom-0 m-0 p-0"
          >
            <div class="cou-info">
              <img src="@/assets/img/icon/icon-01.svg" alt="" />
              <p>12+ Lecciones</p>
            </div>
            <div class="cou-info">
              <img src="@/assets/img/icon/timer-icon.svg" alt="" />
              <p>9hr 30min</p>
            </div>
            <div class="cou-info">
              <img src="@/assets/img/icon/people.svg" alt="" />
              <p>32 Estudiantes matriculados</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Inner Banner -->
</template>
