<template>
  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID de Ticket</th>
          <th>Fecha</th>
          <th>Asunto</th>
          <th>Prioridad</th>
          <th>Categoría</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ticket#003</td>
          <td>27 de marzo de 2024</td>
          <td>No se recibió el correo de activación de cuenta</td>
          <td><span class="resut-badge badge-light-danger">Alta</span></td>
          <td>Problemas de Correo</td>
          <td><span class="status-badge badge-soft-danger">Cerrado</span></td>
        </tr>
        <tr>
          <td>Ticket#005</td>
          <td>24 de abril de 2024</td>
          <td>Pago procesado pero no mostrado</td>
          <td><span class="resut-badge badge-light-success">Baja</span></td>
          <td>Problemas de Pago</td>
          <td><span class="status-badge badge-soft-danger">Cerrado</span></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
