<template>
  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID Referido</th>
          <th>Referencias</th>
          <th>URL</th>
          <th></th>
          <th>Visitas</th>
          <th>Total Ganado</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>09341</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user2.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Thompson Hicks</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>10</td>
          <td>$45.00</td>
        </tr>
        <tr>
          <td>09342</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user4.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Jennifer Tovar</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>15</td>
          <td>$75.00</td>
        </tr>
        <tr>
          <td>09343</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user3.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >James Schulte</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>20</td>
          <td>$100.00</td>
        </tr>
        <tr>
          <td>09344</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user1.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Kristy Cardona</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>1</td>
          <td>$44.00</td>
        </tr>
        <tr>
          <td>09345</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user14.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >William Aragon</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>5</td>
          <td>$25.00</td>
        </tr>
        <tr>
          <td>09346</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user8.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Shirley Lis</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>500</td>
          <td>$160.00</td>
        </tr>
        <tr>
          <td>09347</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user2.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >John Brewer</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>187</td>
          <td>$150.00</td>
        </tr>
        <tr>
          <td>09348</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user5.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Doris Hughes</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>10</td>
          <td>$45.00</td>
        </tr>
        <tr>
          <td>09349</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user13.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Arthur Nalley</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>15</td>
          <td>$10.00</td>
        </tr>
        <tr>
          <td>09350</td>
          <td>
            <h2 class="table-avatar d-flex align-items-center">
              <router-link to="/student/student-profile" class="avatar">
                <img
                  class="avatar-img"
                  src="@/assets/img/user/user6.jpg"
                  alt="Imagen de Usuario"
                />
              </router-link>
              <router-link to="/student/student-profile"
                >Mike Vickers</router-link
              >
            </h2>
          </td>
          <td>
            <span class="text-wrap"
              >https://dreamslmscourse.com/reffer/?refid=345re667877k9</span
            >
          </td>
          <td>
            <a href="javascript:void(0);" class="action-icon"
              ><i class="bx bx-paste"></i
            ></a>
          </td>
          <td>100</td>
          <td>$180.00</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
