<template>
  <div class="table-responsive custom-table">
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID de Pedido</th>
          <th>Nombre del Curso</th>
          <th>Fecha</th>
          <th>Precio</th>
          <th>Estado</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>#2643</td>
          <td>
            <span class="title-course">Construye sitios web responsivos del mundo real con HTML5 y CSS3</span>
          </td>
          <td>24 de marzo de 2024</td>
          <td>$34</td>
          <td>En Espera</td>
          <td>
            <a href="javascript:void(0);" class="action-icon">
              <i class="bx bxs-download"></i>
            </a>
          </td>
        </tr>
        <tr>
          <td>#2644</td>
          <td>
            <span class="title-course">Dibujo de A a Z (2024): Conviértete en diseñador de aplicaciones</span>
          </td>
          <td>26 de marzo de 2024</td>
          <td>$40</td>
          <td>En Espera</td>
          <td>
            <a href="javascript:void(0);" class="action-icon">
              <i class="bx bxs-download"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
