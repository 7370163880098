<template>

  <section class="section share-knowledge" v-if="false">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="knowledge-img aos" data-aos="fade-up">
            <img src="@/assets/img/share.png" alt="" class="img-fluid" />
          </div>
        </div>
        <div class="col-md-6 d-flex align-items-center">
          <div class="join-mentor aos" data-aos="fade-up">
            <h2>¿Quieres compartir tus conocimientos? Únase a nosotros como mentor</h2>
            <p>El vídeo de alta definición es un vídeo de mayor resolución y calidad que
              definición estándar Si bien no existe un significado estandarizado para
              alta definición, generalmente cualquier vídeo.
            </p>
            <ul class="course-list">
              <li><i class="fa-solid fa-circle-check"></i>Mejores cursos</li>
              <li><i class="fa-solid fa-circle-check"></i>Instructores mejor calificados</li>
            </ul>
            <div class="all-btn all-category d-flex align-items-center">
              <router-link to="/instructor/instructor-list" class="btn btn-primary"
                >Leer más</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="section user-love" v-if="false">
    <div class="container">
      <div class="section-header white-header aos" data-aos="fade-up">
        <div class="section-sub-head feature-head text-center">
          <span>Mira estas reseñas reales</span>
          <h2>Los usuarios nos aman No nos lo quites.</h2>
        </div>
      </div>
    </div>
  </section>
  <section class="testimonial-four" v-if="false">
    <div class="review">
      <div class="container">
        <div class="testi-quotes">
          <img src="@/assets/img/qute.png" alt="" />
        </div>
        <div class="mentor-testimonial lazy slider">
          <Carousel
            :wrap-around="true"
            :settings="settings"
            :breakpoints="breakpoints"
          >
            <Slide
              class="d-flex justify-content-center"
              v-for="testimonial in testimonialsindex"
              :key="testimonial.id"
            >
              <div class="testimonial-all d-flex justify-content-center">
                <div class="testimonial-two-head text-center align-items-center d-flex">
                  <div class="testimonial-four-saying">
                    <div class="testi-right">
                      <img src="@/assets/img/qute-01.png" alt="" />
                    </div>
                    <p>{{ testimonial.content }}</p>
                    <div class="four-testimonial-founder">
                      <div class="fount-about-img">
                        <router-link to="/instructor/instructor-profile">
                          <img
                            :src="require(`@/assets/img/user/${testimonial.image}`)"
                            alt=""
                            class="img-fluid"
                          />
                        </router-link>
                      </div>
                      <h3>
                        <router-link to="/instructor/instructor-profile">{{
                          testimonial.name
                        }}</router-link>
                      </h3>
                      <span>{{ testimonial.role }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>

  <section class="section become-instructors aos" data-aos="fade-up" v-if="false">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-6 d-flex">
          <div class="student-mentor cube-instuctor ">
            <h4>Conviértete en instructor</h4>
            <div class="row">
              <div class="col-lg-7 col-md-12">
                <div class="top-instructors">
                  <p>Los mejores instructores de todo el mundo enseñan Mentoring a millones de estudiantes.</p>
                </div>
              </div>
              <div class="col-lg-5 col-md-12">
                <div class="mentor-img">
                  <img class="img-fluid" alt="Img" src="@/assets/img/icon/become-02.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 d-flex">
          <div class="student-mentor yellow-mentor">
            <h4>Transformar el acceso a la educación</h4>
            <div class="row">
              <div class="col-lg-8 col-md-12">
                <div class="top-instructors">
                  <p>Crea una cuenta para recibir nuestro newsletter, recomendaciones de cursos y promociones.</p>
                </div>
              </div>
              <div class="col-lg-4 col-md-12">
                <div class="mentor-img">
                  <img class="img-fluid" alt="Img" src="@/assets/img/icon/become-01.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import testimonialsindex from "@/assets/json/testimonialsindex.json";
import { Carousel, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      testimonialsindex: testimonialsindex,
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },

      breakpoints: {
        575: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        767: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        991: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        // 1024 and up
        1024: {
          itemsToShow: 1,
          snapAlign: "center",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      AOS.init();
    });
  },
};
</script>
