<template>
  <!-- barra lateral -->
  <div class="col-xl-3 col-lg-3 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="settings-widget dash-profile">
        <div class="settings-menu">
          <div class="profile-bg">
            <div class="profile-img">
              <img :src="src" alt="Img">
            </div>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4 v-if="estudinate != null">
                {{ estudinate.nombres + ' ' + estudinate.apellidos }}
              </h4>
              <h4 v-else>
                Administrador
              </h4>
              <p v-if="estudinate">Estudiante</p>
              <p v-else>Administrador</p>
            </div>
          </div>
        </div>
      </div>
      <div class="settings-widget account-settings">
        <div class="settings-menu">
          <ul>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-settings') }"
            >
              <router-link to="/student/student-settings" class="nav-link">
                <i class="bx bxs-user"></i>Mi Perfil
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-change-password') }"
            >
              <router-link to="/student/student-change-password" class="nav-link">
                <i class="bx bxs-lock"></i>Contraseña
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-courses') }"
            >
              <router-link to="/student/student-courses" class="nav-link">
                <i class="bx bxs-book"></i>Mis Cursos
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-certificados') }"
            >
              <router-link to="/student/student-certificados" class="nav-link">
                <i class="bx bxs-graduation"></i>Certificados
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-order-history') }"
            >
              <router-link to="/student/student-order-history" class="nav-link">
                <i class="bx bxs-cart"></i>Historial de Pedidos
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/student/student-dashboard') }"
            >
              <router-link to="/student/student-dashboard" class="nav-link">
                <i class="bx bxs-tachometer"></i>Panel de Control
              </router-link>
            </li>
              <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/student/student-wishlist') }"
            >
              <router-link to="/student/student-wishlist" class="nav-link">
                <i class="bx bxs-heart"></i>Lista de Deseos
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-reviews' )  } " 
              v-if="false"
            >
              <router-link to="/student/student-reviews" class="nav-link">
                <i class="bx bxs-star"></i>Reseñas
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{
                active:
                  isActive('/student/student-quiz') ||
                  isActive('/student/student-quiz-details'),
              }"
              v-if="false"
            >
              <router-link to="/student/student-quiz" class="nav-link">
                <i class="bx bxs-shapes"></i>Mis Intentos de Cuestionarios
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-order-history') }"
            >
              <router-link to="/student/student-order-history" class="nav-link"  v-if="false">
                <i class="bx bxs-cart"></i>Historial de Pedidos
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/student/student-qa') }"
            >
              <router-link to="/student/student-qa" class="nav-link" v-if="false">
                <i class="bx bxs-bookmark-alt"></i>Pregunta y Respuesta
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/student/student-referral') }"
            >
              <router-link to="/student/student-referral" class="nav-link">
                <i class="bx bxs-user-plus"></i>Referencias
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/student/student-messages') }"
            >
              <router-link to="/student/student-messages" class="nav-link">
                <i class="bx bxs-chat"></i>Mensajes
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/student/student-tickets') }"
            >
              <router-link to="/student/student-tickets" class="nav-link">
                <i class="bx bxs-coupon"></i>Tickets de Soporte
              </router-link>
            </li>
          </ul>
          <ul>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/student/student-settings') }"
            >
              <router-link to="/student/student-settings" class="nav-link">
                <i class="bx bxs-cog"></i>Configuraciones
              </router-link>
            </li>
            <li class="nav-item" v-if="false">
              <router-link to="/home/" class="nav-link">
                <i class="bx bxs-log-out"></i>Cerrar Sesión
              </router-link>
            </li>
            <button
              class="nav-link"
              @click="cerrarSesion"
              style="background: transparent; border: none"
            >
              <i class="bx bxs-log-out"></i>Cerrar Sesión
            </button>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- /barra lateral -->
</template>
<script>
import { useAuth } from "@/stores";
import UserService from "@/service/UserService";

export default {
  data() {
    return {
      activeClass: "active",
      Available: ["Estoy Disponible Ahora", "No Disponible"],
      src: require("@/assets/img/user/avatar.png"),
      estudinate : null,
    };
  },
  created() {
    this.auth = useAuth();
    this.userService = new UserService();
  },
  mounted() {
    this.obtenerdatos();
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    obtenerdatos() {
      this.userService.getUser().then((response) => {
        if (!response.success) {
          return;
        }
        this.estudinate = response.usuario;
        this.src = response.usuario.foto
        ? response.usuario.foto
        : require("@/assets/img/user/avatar.png");
      });
    },
    cerrarSesion() {
      if (confirm("¿Está seguro de cerrar sesión?")) this.auth.userLogout();
    },
  },
};
</script>
