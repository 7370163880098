<template>
  <div class="col-xl-3 col-lg-3 theiaStickySidebar">
    <div class="stickysidebar">
      <div class="settings-widget dash-profile">
        <div class="settings-menu">
          <div class="profile-bg">
            <div class="profile-img">
              <div>
                <img :src="src" alt="Img">
              </div>
            </div>
          </div>
          <div class="profile-group">
            <div class="profile-name text-center">
              <h4 v-if="instructor != null">
                {{ instructor.nombres + " " + instructor.apellidos }}
              </h4>
              <h4 v-else>Administrador</h4>
              <p v-if="instructor">Instructor</p>
              <p v-else>Administrador</p>
              <router-link
                v-if="false"
                to="/course/add-course"
                class="add-course btn-primary"
                >Agregar Nuevo Curso</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="settings-widget account-settings">
        <div class="settings-menu">
          <h3>Panel de Control</h3>
          <ul>
            <li
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-dashboard') }"
            >
              <router-link
                to="/instructor/instructor-dashboard"
                class="nav-link"
              >
                <i class="bx bxs-tachometer"></i>Panel de Control
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-profile') }"
            >
              <router-link to="/instructor/instructor-profile" class="nav-link">
                <i class="bx bxs-user"></i>Mi Perfil
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('/instructor/instructor-enrolled-course'),
              }"
            >
              <router-link
                to="/instructor/instructor-enrolled-course"
                class="nav-link"
              >
                <i class="bx bxs-graduation"></i>Cursos Inscritos
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-wishlist') }"
            >
              <router-link
                to="/instructor/instructor-wishlist"
                class="nav-link"
              >
                <i class="bx bxs-heart"></i>Lista de Deseos
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-reviews') }"
            >
              <router-link to="/instructor/instructor-reviews" class="nav-link">
                <i class="bx bxs-star"></i>Reseñas
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-quiz') }"
            >
              <router-link to="/instructor/instructor-quiz" class="nav-link">
                <i class="bx bxs-shapes"></i>Mis Intentos de Quiz
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-orders') }"
            >
              <router-link to="/instructor/instructor-orders" class="nav-link">
                <i class="bx bxs-cart"></i>Historial de Pedidos
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-qa') }"
            >
              <router-link to="/instructor/instructor-qa" class="nav-link">
                <i class="bx bxs-bookmark-alt"></i>Pregunta y Respuesta
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-referral') }"
            >
              <router-link
                to="/instructor/instructor-referral"
                class="nav-link"
              >
                <i class="bx bxs-user-plus"></i>Referidos
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-chat') }"
            >
              <router-link to="/instructor/instructor-chat" class="nav-link">
                <i class="bx bxs-chat"></i>Mensajes
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{
                active: isActive('/instructor/instructor-notifications'),
              }"
            >
              <router-link
                v-if="false"
                to="/instructor/instructor-notifications"
                class="nav-link"
              >
                <i class="bx bxs-bell"></i>Notificaciones
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-tickets') }"
            >
              <router-link to="/instructor/instructor-tickets" class="nav-link">
                <i class="bx bxs-coupon"></i>Tickets de Soporte
              </router-link>
            </li>
          </ul>
          <h3>Instructor</h3>
          <ul>
            <li
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-course') }"
            >
              <router-link to="/instructor/instructor-course" class="nav-link">
                <i class="bx bxs-rocket"></i>Mis Cursos
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{
                active: isActive('/instructor/instructor-announcements'),
              }"
            >
              <router-link
                to="/instructor/instructor-announcements"
                class="nav-link"
              >
                <i class="bx bxs-volume-full"></i>Anuncios
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-withdraw') }"
            >
              <router-link
                v-if="false"
                to="/instructor/instructor-withdraw"
                class="nav-link"
              >
                <i class="bx bxs-wallet"></i>Retiradas
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{
                active: isActive('/instructor/instructor-quiz-attempts'),
              }"
            >
              <router-link
                to="/instructor/instructor-quiz-attempts"
                class="nav-link"
              >
                <i class="bx bxs-shapes"></i>Intentos de Quiz
              </router-link>
            </li>
            <li
              v-if="false"
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-assignment') }"
            >
              <router-link
                to="/instructor/instructor-assignment"
                class="nav-link"
              >
                <i class="bx bxs-file"></i>Tareas
              </router-link>
            </li>
            <li
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-earnings') }"
            >
              <router-link
                v-if="false"
                to="/instructor/instructor-earnings"
                class="nav-link"
              >
                <i class="bx bxs-badge-dollar"></i>Ganancias
              </router-link>
            </li>
          </ul>
          <h3>Ajustes de Cuenta</h3>
          <ul>
            <li
              class="nav-item"
              :class="{ active: isActive('/instructor/instructor-settings') }"
            >
              <router-link
                to="/instructor/instructor-settings"
                class="nav-link"
              >
                <i class="bx bxs-cog"></i>Ajustes
              </router-link>
            </li>
            <li class="nav-item">
              <!-- <router-link to="/" class="nav-link">
                <i class="bx bxs-log-out"></i>Cerrar Sesión
              </router-link> -->
              <div class="nav-link">
                <a href="#" @click="cerrarSesion" class="nav-link">
                  <i class="bx bxs-log-out"></i>Cerrar Sesión
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useAuth } from "@/stores";
import UserService from "@/service/UserService";

export default {
  data() {
    return {
      activeClass: "active",
      Available: ["Estoy Disponible Ahora", "No Disponible"],
      src: require("@/assets/img/user/avatar.png"),
      instructor: null,
    };
  },
  created() {
    this.auth = useAuth();
    this.userService = new UserService();
  },
  mounted() {
    this.obtenerdatos();
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    obtenerdatos() {
      this.userService.getUser().then((response) => {
        if (!response.success) {
          return;
        }
        this.instructor = response.usuario;
        this.src = response.usuario.foto
        ? response.usuario.foto 
        : require("@/assets/img/user/avatar.png");
      });
    },
    cerrarSesion() {
      if (confirm("¿Está seguro de cerrar sesión?")) this.auth.userLogout();
    },
  },
};
</script>
