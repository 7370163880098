<template>
  <div class="table-responsive custom-table">
    <!-- Usuarios Referidos-->
    <table class="table table-nowrap mb-0">
      <thead>
        <tr>
          <th>ID de Ticket</th>
          <th>Fecha</th>
          <th>Asunto</th>
          <th>Prioridad</th>
          <th>Categoría</th>
          <th>Estado</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Ticket#001</td>
          <td>12 de marzo de 2024</td>
          <td>Necesito un software de freelance</td>
          <td>
            <span class="resut-badge badge-light-success">Baja</span>
          </td>
          <td>Problemas de Correo</td>
          <td>
            <span class="status-badge badge-soft-success">Abierto</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#003</td>
          <td>27 de marzo de 2024</td>
          <td>No he recibido el correo de activación de cuenta</td>
          <td>
            <span class="resut-badge badge-light-danger">Alta</span>
          </td>
          <td>Problemas de Correo</td>
          <td>
            <span class="status-badge badge-soft-danger">Cerrado</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#004</td>
          <td>04 de abril de 2024</td>
          <td>Habilitando el servicio SSH</td>
          <td>
            <span class="resut-badge badge-light-warning">Media</span>
          </td>
          <td>Error de Instalación</td>
          <td>
            <span class="status-badge badge-soft-success">Abierto</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#007</td>
          <td>03 de junio de 2024</td>
          <td>Velocidad lenta al descargar el curso</td>
          <td>
            <span class="resut-badge badge-light-danger">Alta</span>
          </td>
          <td>Problemas de Servidor</td>
          <td>
            <span class="status-badge badge-soft-success">Abierto</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#008</td>
          <td>13 de junio de 2024</td>
          <td>No puedo acceder al curso</td>
          <td>
            <span class="resut-badge badge-light-warning">Media</span>
          </td>
          <td>Problema de Demostración</td>
          <td>
            <span class="status-badge badge-soft-success">Abierto</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#009</td>
          <td>15 de julio de 2024</td>
          <td>Error de carga de archivos de tarea</td>
          <td>
            <span class="resut-badge badge-light-success">Baja</span>
          </td>
          <td>Error de Archivo</td>
          <td>
            <span class="status-badge badge-soft-success">Abierto</span>
          </td>
        </tr>
        <tr>
          <td>Ticket#0010</td>
          <td>23 de julio de 2024</td>
          <td>Necesito asistencia para recuperación de cuenta</td>
          <td>
            <span class="resut-badge badge-light-danger">Alta</span>
          </td>
          <td>Problemas de Recuperación</td>
          <td>
            <span class="status-badge badge-soft-success">Abierto</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
