<template>
  <div class="settings-page-head">
    <ul class="settings-pg-links">
      <li>
        <router-link to="/student/student-settings"
          ><i class="bx bx-edit"></i>Editar Perfil</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-change-password"
          ><i class="bx bx-lock"></i>Cambiar Contraseña</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-social-profile"  v-if="false"
          ><i class="bx bx-user-circle"></i>Perfiles Sociales</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-linked-accounts"  v-if="false"
          ><i class="bx bx-link"></i>Cuentas Vinculadas</router-link
        >
      </li>
      <li>
        <router-link to="/student/student-notifications"  v-if="false"
          ><i class="bx bx-bell"></i>Notificaciones</router-link
        >
      </li>
    </ul>
  </div>
</template>
