<template>
  <div class="row" v-for="cursos in cursos_publicados">
    <!-- Rejilla de Cursos -->
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                :src="cursos.imagen"
              />
            </router-link>
            <div class="price">
              <h3>Bs{{ cursos.precio }}<span> Bs00.00</span></h3>
            </div>
          </div>
          <div class="product-content">
            <h3 class="title instructor-text">
              <router-link to="/course/course-details"
                >{{ cursos.nombre }}</router-link
              >
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>{{ cursos.temas }} + Temas</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>{{ cursos.duracion }}</p>
              </div>
            </div>
            <div
              class="course-edit-btn d-flex align-items-center justify-content-between"
            >
              <a href="#"><i class="bx bx-edit me-2"></i>Editar</a>
              <a href="#"><i class="bx bx-trash me-2"></i>Eliminar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Rejilla de Cursos -->
  </div>
</template>

<script>
export default {
  name: "MyCourses",
  props: {
    cursos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cursos_publicados: this.cursos,
    };
  },
  watch: {
    cursos(value){
      this.cursos_publicados = value;
    }
  },
};
</script>

<style scoped></style>
