<template>
  <page-header></page-header>
  <checkoutbreadcrumb></checkoutbreadcrumb>
  <!-- Carrito -->
  <section class="course-content checkout-widget">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <!-- Dirección de Facturación -->
          <div class="student-widget">
            <div class="student-widget-group add-course-info">
              <div class="cart-head">
                <h4>Realizar Pago</h4>
              </div>
              <div class="checkout-form">
                <form @submit.prevent="handleSubmit">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">Nombre</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingresa tu nombre"
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group">
                        <label class="form-control-label">Apellido</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Ingresa tu apellido"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="form-control-label">Número de Teléfono (Opcional)</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Número de Teléfono"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label class="form-control-label">Dirección Línea 1</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Dirección"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Dirección de Facturación -->

          <!-- /Método de Pago -->
          <div class="student-widget pay-method">
            <div class="student-widget-group add-course-info">
              <div class="cart-head">
                <h4>Método de Pago</h4>
              </div>
              <div class="checkout-form">
                <form>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="wallet-method">
                        <label class="radio-inline custom_radio me-4">
                          <input type="radio" name="optradio" @change="toggleQR" />
                          <span class="checkmark"></span> Pago por QR
                        </label>
                      </div>
                      <div v-if="showQR" class="qr-code text-center">
                        <img src="@/assets/img/qr.png" alt="Código QR" />
                      </div>
                    </div>
                    <div class="payment-btn">
                      <button class="btn btn-primary" alt="pago" type="submit" @click="handleSubmit">
                        Realizar el Pago
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- /Método de Pago -->
        </div>
        <div class="col-lg-4 theiaStickySidebar">
          <div class="stickysidebar">
            <div class="student-widget select-plan-group">
              <div class="student-widget-group">
                <div class="plan-header">
                  <h4>Plan Seleccionado</h4>
                </div>
                <div class="basic-plan">
                  <h3>Básico</h3>
                  <p>Para individuos que solo necesitan comenzar con las funciones básicas</p>
                  <p>por usuario, por mes cuando se factura mensualmente</p>
                  <h2><span>Bs.</span>10</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /Carrito -->

  <layouts1></layouts1>
</template>

<script>
export default {
  data() {
    return {
      showQR: false,
      State: ["Seleccionar Estado", "Brasil", "Francia"],
      country: ["Seleccionar país", "India", "América", "Londres"],
      Month: ["Mes", "Jun", "Feb", "Marzo"],
      Year: ["Año", "2023", "2023", "2023"],
    };
  },
  methods: {
    toggleQR() {
      this.showQR = !this.showQR;
    },
    handleSubmit() {
      alert('Pago satisfactorio');
    }
  },
};
</script>

<style>
.qr-code {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  
}
.pago{
  justify-content: center;
  display: flex;
}
</style>
