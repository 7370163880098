<template>
    <header-menu></header-menu>
</template>
<script>
export default {
  components: {},
  data() {
    return {
    };
  },
};
</script>
