<template>
  <student-header></student-header>
  <!-- Breadcrumb -->
  <div class="breadcrumb-bar">
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-12">
          <div class="breadcrumb-list">
            <nav aria-label="breadcrumb" class="page-breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/home/">Inicio</router-link></li>
                <li class="breadcrumb-item">Páginas</li>
                <li class="breadcrumb-item">Cuadrícula de Estudiantes</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- /Breadcrumb -->

  <!-- Page Wrapper -->
  <div class="page-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <!-- Filter -->
          <div class="showing-list">
            <div class="row">
              <div class="col-lg-6">
                <div class="d-flex align-items-center">
                  <div class="view-icons">
                    <router-link to="students-grid" class="grid-view active"
                      ><i class="feather-grid"></i
                    ></router-link>
                    <router-link to="/student/students-list" class="list-view" v-if="false"
                      ><i class="feather-list"></i
                    ></router-link>
                  </div>
                  <div class="show-result" v-if="false">
                    <h4>Mostrando 1-9 de 50 resultados</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /Filter -->

          <div class="row" v-for="estudiante in paginatedEstudiante" :key="estudiante.id">
            <div class="col-lg-4 col-md-6 d-flex">
              <div class="student-box flex-fill">
                <div class="student-img">
                  <router-link to="/student/student-profile">
                    <img
                      class="img-fluid"
                      alt="Información del Estudiante"
                      :src="estudiante.imagen ||
                        require('@/assets/img/user/avatar.png')"
                    />
                  </router-link>
                </div>
                <div class="student-content pb-0">
                  <h5>
                    {{ estudiante.nombres + ' ' + estudiante.apellidos }}
                  </h5>
                  <h6>Estudiante</h6>
                  <div class="loc-blk d-flex align-items-center justify-content-center">
                    <img src="@/assets/img/students/loc-icon.svg" class="me-1" alt="" />
                    <p>{{ estudiante.pais || 'pais'}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <pagination></pagination>
        </div>
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>
<script>
import Pagination from "@/components/lms-pagination.vue";
import EstudianteService from "@/service/EstudianteService";

export default {
  components: {
    Pagination,
  },
  data() {
    return {
      estudiantes: [],
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.estudiantes.length / this.itemsPerPage);
    },
    paginatedEstudiante() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.estudiantes.slice(start, end);
    },
  },
  created() {
    this.estudianteService = new EstudianteService();
  },
  mounted() {
    this.obtenerEstudiantes();
  },
  methods: {
    obtenerEstudiantes() {
      this.estudianteService.getEstudiantes().then((response) => {
        if (!response.success) {
          return;
        }
        this.estudiantes = response.estudiantes;
      });
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
  },
};
</script>
