<template>
  <layouts></layouts>
  <student-breadcrumb :title="title" :text="text" :text1="text1" />
  <div class="page-content">
    <div class="container">
      <div class="row">
        <!-- barra lateral -->
        <instructor-sidebar></instructor-sidebar>
        <!-- /Barra lateral -->

        <!-- Cursos del Instructor -->
        <div class="col-xl-9 col-lg-9">
          <div class="settings-widget card-info">
            <div class="settings-menu p-0">
              <div class="profile-heading">
                <h3>Mis Cursoseeeee</h3>
                <p>Administra tus cursos y sus actualizaciones</p>
              </div>
              <div class="checkout-form pb-0">
                <div class="wishlist-tab">
                  <ul class="nav">
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        class="active"
                        data-bs-toggle="tab"
                        data-bs-target="#enroll-courses"
                        >Publicados (6)</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#active-courses"
                        >Pendiente (2)</a
                      >
                    </li>
                    <li class="nav-item">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="tab"
                        data-bs-target="#complete-courses"
                        >Borrador (1)</a
                      >
                    </li>
                  </ul>
                </div>

                <div class="tab-content">
                  <div class="tab-pane fade show active" id="enroll-courses">
                    <instructor-enroll-course
                      :cursos="cursoPublicados"
                    ></instructor-enroll-course>
                  </div>

                  <div class="tab-pane fade" id="active-courses">
                    <instructor-active-course
                      :cursos="cursoPendientes"
                    ></instructor-active-course>
                  </div>

                  <div class="tab-pane fade" id="complete-courses">
                    <instructor-complete-course
                      :cursos="cursoDesactivados"
                    ></instructor-complete-course>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="dash-pagination">
            <div class="row align-items-center">
              <div class="col-6">
                <p>Página 1 de 2</p>
              </div>
              <div class="col-6">
                <ul class="pagination">
                  <li class="active">
                    <a href="#">1</a>
                  </li>
                  <li>
                    <a href="#">2</a>
                  </li>
                  <li>
                    <a href="#"><i class="bx bx-chevron-right"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- /Cursos del Instructor -->
      </div>
    </div>
  </div>

  <layouts1></layouts1>
</template>
<script>
import CursoService from "@/service/CursoService";

export default {
  data() {
    return {
      title: "Mis Cursos",
      text: "Inicio",
      text1: "Mis Cursos",
      cursoPublicados: [],
      cursoDesactivados: [],
      cursoPendientes: [],
    };
  },
  created() {
    this.cursoService = new CursoService();
  },
  mounted() {
    this.obtenerCursos();
  },
  methods: {
    obtenerCursos() {
      this.cursoService.getCursoPorInstructor().then((response) => {
        if (response.success) {
          for (let i = 0; i < response.cursos.length; i++) {
            switch (response.cursos[i].estado) {
              case 1:
                this.cursoPublicados.push(response.cursos[i]);
                break;
              case 2:
                this.cursoPendientes.push(response.cursos[i]);
                break;
              case 0:
                this.cursoDesactivados.push(response.cursos[i]);
                break;
            }
          }
        } else {
          this.message.error(response.message);
        }
      });
    },
  },
};
</script>
