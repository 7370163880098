<template>
  <layouts-login></layouts-login>
  <blogmasonry></blogmasonry>
  <!-- Blog Grid -->
  <section class="course-content">
    <div class="container">
      <div class="row masonry-blog-blk">
        <div class="col-lg-4 col-md-6">
          <!-- Publicación del Blog -->
          <div class="blog grid-blog">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-08.jpg"
                  alt="Imagen de la Publicación"
              /></router-link>
            </div>
            <div class="blog-grid-box masonry-box">
              <div class="blog-info clearfix">
                <div class="post-left">
                  <ul>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-22.svg"
                        alt=""
                      />14 de junio, 2023
                    </li>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-23.svg"
                        alt=""
                      />Programación, Diseño Web
                    </li>
                  </ul>
                </div>
              </div>
              <h3 class="blog-title">
                <router-link to="/blog/blog-details"
                  >Aprende Desarrollo de Aplicaciones Web con Expertos</router-link
                >
              </h3>
              <div class="blog-content blog-read">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                  hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
                </p>
                <router-link to="/blog/blog-details" class="read-more btn btn-primary"
                  >Leer Más</router-link
                >
              </div>
            </div>
          </div>
          <!-- /Publicación del Blog -->

          <!-- Publicación del Blog -->
          <div class="blog grid-blog">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-11.jpg"
                  alt="Imagen de la Publicación"
              /></router-link>
            </div>
            <div class="blog-grid-box masonry-box">
              <div class="blog-info clearfix">
                <div class="post-left">
                  <ul>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-22.svg"
                        alt=""
                      />18 de mayo, 2023
                    </li>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-23.svg"
                        alt=""
                      />Programación, Cursos
                    </li>
                  </ul>
                </div>
              </div>
              <h3 class="blog-title">
                <router-link to="/blog/blog-details"
                  >Guía de Contenidos de Programación para Principiantes</router-link
                >
              </h3>
              <div class="blog-content blog-read">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                  hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
                </p>
                <router-link to="/blog/blog-details" class="read-more btn btn-primary"
                  >Leer Más</router-link
                >
              </div>
            </div>
          </div>
          <!-- /Publicación del Blog -->
        </div>
        <div class="col-lg-4 col-md-6">
          <!-- Publicación del Blog -->
          <div class="blog grid-blog">
            <div class="blog-image">
              <router-link to="/blog/blog-details"
                ><img
                  class="img-fluid"
                  src="@/assets/img/blog/blog-09.jpg"
                  alt="Imagen de la Publicación"
              /></router-link>
            </div>
            <div class="blog-grid-box masonry-box">
              <div class="blog-info clearfix">
                <div class="post-left">
                  <ul>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-22.svg"
                        alt=""
                      />24 de mayo, 2023
                    </li>
                    <li>
                      <img
                        class="img-fluid"
                        src="@/assets/img/icon/icon-23.svg"
                        alt=""
                      />Programación, Cursos
                    </li>
                  </ul>
                </div>
              </div>
              <h3 class="blog-title">
                <router-link to="/blog/blog-details"
                  >Amplía tus Oportunidades de Carrera con Python</router-link
                >
              </h3>
              <div class="blog-content blog-read">
                <p>
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
                  hendrerit. Pellentesque aliquet nibh nec urna. In nisi neque, aliquet
                  vel, dapibus id, mattis vel, nisi. Sed pretium, ligula sollicitudin
                  laoreet viverra, tortor libero sodales leo, eget blandit nunc tortor
                  eu nibh. Nullam mollis. Ut justo. Suspendisse potenti. Sed egestas,
                  ante et vulputate volutpat, eros pede […]
                </p>
                <router-link to="/blog/blog-details" class="read-more btn btn-primary"
                  >Leer Más</router-link
                >
              </div>
            </div>
          </div>
          <!-- /Publicación del Blog -->
    <!-- Publicación del Blog -->
    <div class="blog grid-blog">
      <div class="blog-image">
        <router-link to="/blog/blog-details"
          ><img
            class="img-fluid"
            src="@/assets/img/blog/blog-09.jpg"
            alt="Imagen de la Publicación"
        /></router-link>
      </div>
      <div class="blog-grid-box masonry-box">
        <div class="blog-info clearfix">
          <div class="post-left">
            <ul>
              <li>
                <img
                  class="img-fluid"
                  src="@/assets/img/icon/icon-22.svg"
                  alt=""
                />24 de mayo, 2023
              </li>
              <li>
                <img
                  class="img-fluid"
                  src="@/assets/img/icon/icon-23.svg"
                  alt=""
                />Programación, Cursos
              </li>
            </ul>
          </div>
        </div>
        <h3 class="blog-title">
          <router-link to="/blog/blog-details"
            >Amplía tus Oportunidades de Carrera con Python</router-link
          >
        </h3>
        <div class="blog-content blog-read">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
            hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
          </p>
          <router-link to="/blog/blog-details" class="read-more btn btn-primary"
            >Leer Más</router-link
          >
        </div>
      </div>
    </div>
    <!-- /Publicación del Blog -->
  </div>
  <div class="col-lg-4 col-md-6">
    <!-- Publicación del Blog -->
    <div class="blog grid-blog">
      <div class="blog-image">
        <router-link to="/blog/blog-details"
          ><img
            class="img-fluid"
            src="@/assets/img/blog/blog-10.jpg"
            alt="Imagen de la Publicación"
        /></router-link>
      </div>
      <div class="blog-grid-box masonry-box">
        <div class="blog-info clearfix">
          <div class="post-left">
            <ul>
              <li>
                <img
                  class="img-fluid"
                  src="@/assets/img/icon/icon-22.svg"
                  alt=""
                />14 de junio, 2023
              </li>
              <li>
                <img
                  class="img-fluid"
                  src="@/assets/img/icon/icon-23.svg"
                  alt=""
                />Programación, Diseño Web
              </li>
            </ul>
          </div>
        </div>
        <h3 class="blog-title">
          <router-link to="/blog/blog-details"
            >Guía Completa de Carrera en Programación PHP</router-link
          >
        </h3>
        <div class="blog-content blog-read">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
            hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
          </p>
          <router-link to="/blog/blog-details" class="read-more btn btn-primary"
            >Leer Más</router-link
          >
        </div>
      </div>
    </div>
    <!-- /Publicación del Blog -->

    <!-- Publicación del Blog -->
    <div class="blog grid-blog">
      <div class="blog-image">
        <router-link to="/blog/blog-details"
          ><img
            class="img-fluid"
            src="@/assets/img/blog/blog-13.jpg"
            alt="Imagen de la Publicación"
        /></router-link>
      </div>
      <div class="blog-grid-box masonry-box">
        <div class="blog-info clearfix">
          <div class="post-left">
            <ul>
              <li>
                <img
                  class="img-fluid"
                  src="@/assets/img/icon/icon-22.svg"
                  alt=""
                />14 de febrero, 2023
              </li>
              <li>
                <img
                  class="img-fluid"
                  src="@/assets/img/icon/icon-23.svg"
                  alt=""
                />Programación, Cursos
              </li>
            </ul>
          </div>
        </div>
        <h3 class="blog-title">
          <router-link to="/blog/blog-details"
            >Aprende Desarrollo de Aplicaciones Móviles con Expertos</router-link
          >
        </h3>
        <div class="blog-content blog-read">
          <p>
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Phasellus
            hendrerit. Sed egestas, ante et vulputate volutpat, eros pede […]
          </p>
          <router-link to="/blog/blog-details" class="read-more btn btn-primary"
            >Leer Más</router-link
          >
        </div>
      </div>
    </div>
    <!-- /Publicación del Blog -->
  </div>
</div>

<!-- Botón de Carga -->
<div class="load-more text-center">
  <a href="javascript:;" class="btn btn-primary">Cargar Más</a>
</div>
<!-- /Botón de Carga -->
</div>
</section>
<!-- /Rejilla del Blog -->

<layouts1></layouts1>
</template>
