<template>
  <!-- Footer -->
  <footer class="footer footer-three">
    <!-- Footer Top -->
    <div class="footer-three-top" data-aos="fade-up">
      <div class="container">
        <div class="footer-three-top-content">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6 col-12">
              <!-- Footer Widget -->
              <div class="footer-widget-three footer-about">
                <div class="footer-three-logo">
                  <img class="img-fluid" src="@/assets/img/logo/logo.png" alt="logo" />
                </div>
                <div class="footer-three-about">
                  <p>
                    El cliente es muy importante, el cliente será seguido por el cliente. Que no haya ninguno
                    embarazada como la necesidad de disparar el arco. Pero el chocolate sufre mucho.
                    la masa del lago mismo. Lobortis acaba de poner el patio y la masa.
                    Mecenas, la tierra de los adipiscentes y de los hambres de ponerse. algunos, enteros
                  </p>
                </div>
                <div class="newsletter-title">
                  <h6>Obtener actualizaciones</h6>
                </div>
                <div class="box-form-newsletter">
                  <form class="form-newsletter">
                    <input
                      class="input-newsletter"
                      type="text"
                      placeholder="Enter your email here"
                    />
                    <button class="btn btn-default font-heading icon-send-letter">
                      Suscríbete ahora
                    </button>
                  </form>
                </div>
              </div>
              <!-- /Footer Widget -->
            </div>

            <div class="col-lg-3 col-md-3 col-12">
              <!-- Footer Widget -->
              <div class="footer-widget-three footer-menu-three footer-three-right">
                <h6 class="footer-three-title">Para el instructor</h6>
                <ul>
                  <li><router-link to="/instructor/instructor-profile">Perfil</router-link></li>
                  <li><router-link to="/">Acceso</router-link></li>
                  <li><router-link to="/register">Registro</router-link></li>
                  <li><router-link to="/instructor/instructor-list">Instructor</router-link></li>
                  <li>
                    <router-link to="/instructor/instructor-dashboard">
                      panel</router-link
                    >
                  </li>
                </ul>
              </div>
              <!-- /Footer Widget -->
            </div>

            <div class="col-lg-3 col-md-3 col-12">
              <!-- Footer Widget -->
              <div class="footer-widget-three footer-menu-three">
                <h6 class="footer-three-title">Para estudiantes</h6>
                <ul>
                  <li><router-link to="/student/student-profile">Perfil</router-link></li>
                  <li><router-link to="/">Login</router-link></li>
                  <li><router-link to="/register">Register</router-link></li>
                  <li><router-link to="/student/students-list">Alumno</router-link></li>
                  <li>
                    <router-link to="/student/student-dashboard"> Panel</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Footer Widget -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-three-bottom" data-aos="fade-up">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright-three">
          <div class="row">
            <div class="col-md-12">
              <div class="social-icon-three">
                <h6>Conéctese socialmente</h6>
                <ul>
                  <li>
                    <a href="javascript:;" target="_blank" class="feather-facebook-icon">
                      <i class="feather-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank" class="feather-twitter-icon">
                      <i class="feather-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank" class="feather-linkedin-icon">
                      <i class="feather-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:;" target="_blank" class="feather-youtube-icon">
                      <i class="feather-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="privacy-policy-three">
                <ul>
                  <li>
                    <router-link to="/pages/term-condition">Términos y condiciones</router-link>
                  </li>
                  <li><router-link to="/pages/privacy-policy">política de privacidad</router-link></li>
                  <li><router-link to="/pages/support">Contáctenos</router-link></li>
                </ul>
              </div>
              <div class="copyright-text-three">
                <p class="mb-0">
                  &copy; {{ new Date().getFullYear() }} SueñosLMS. Reservados todos los derechos.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
    <!-- /Footer Bottom -->
  </footer>
  <!-- /Footer -->
</template>
