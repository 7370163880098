<template>
  <!-- Login Banner -->
  <div class="col-lg-5 col-md-7 login-bg fondo_banner_register">
    <div class="owl-carousel login-slide owl-theme">
      <div class="welcome-login register-step">
        <div class="login-banner">
          <img src="@/assets/img/logo_papo.png" class="img-fluid" alt="Logo" />
        </div>
        <Carousel>
          <Slide v-for="slide in 3" :key="slide">
            <div class="mentor-course text-center">
              <h2>Bienvenido a <br />Cursos de Papo Network.</h2>
              <p>
  Papo Network te conecta con instructores expertos para ofrecerte cursos 
  de alta calidad en tecnología, negocios, diseño y más.
</p>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
    </div>
  </div>
  <!-- /Login Banner -->
</template>
<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide } from "vue3-carousel";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Pagination,
  },
});
</script>

<style>
.fondo_banner_register {
  background: linear-gradient(90deg, #14123b, transparent) !important;
}
</style>
