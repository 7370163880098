<template>
  <div class="row" v-for="curso in cursos_pendientes">
    <!-- Cuadro de Cursos -->
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img
                class="img-fluid"
                alt="Img"
                :src="curso.imagen"
              />
            </router-link>
            <div class="price">
              <h3>Bs.{{ curso.precio }} <span>Bs.90.00</span></h3>
            </div>
          </div>
          <div class="product-content">
            <h3 class="title instructor-text">
              <router-link to="/course/course-details"
                >{{ curso.nombre }}</router-link
              >
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>{{ curso.temas }} + Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>{{ curso.duracion }}</p>
              </div>
            </div>
            <div
              class="course-edit-btn d-flex align-items-center justify-content-between"
            >
              <a href="#"><i class="bx bx-edit me-2"></i>Editar</a>
              <a href="#"><i class="bx bx-trash me-2"></i>Eliminar</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cuadro de Cursos -->

  </div>
</template>
<script>
export default {
  props: {
    cursos: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      cursos_pendientes: this.cursos,
    };
  },
  watch: {
    cursos(value){
      this.cursos_pendientes = value;
    }
  },
};
</script>
