<template>
  <layouts-loginborder></layouts-loginborder>

  <pricingplan></pricingplan>
  <!-- Plan de precios -->
  <section class="course-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-5 text-center mx-auto">
          <div class="title-sec">
            <h5>Lo mantenemos simple</h5>
            <h2>Elige el plan adecuado</h2>
            <p>
              Mejora la forma en que trabajas, descubre una herramienta nueva y
              despídete de las molestias de una vez por todas.
            </p>
          </div>
          <div class="text-end">
            <img src="@/assets/img/plan.png" alt="icono" />
          </div>
          <div class="enable-item">
            <label class="mb-0 me-2">Mensual</label>
            <div class="form-check form-switch check-on m-0">
              <input
                class="form-check-input"
                type="checkbox"
                id="flexSwitchCheckChecked"
                checked
              />
            </div>
            <label class="mb-0 ms-2">Anual</label>
          </div>
        </div>
      </div>

      <!-- Tipo de Plan -->
      <div class="row">
        <div class="col-lg-8">
          <div class="plan-box">
            <div>
              <h4>Básico</h4>
              <p>
                Para personas que solo necesitan comenzar con las funciones
                básicas
              </p>
              <p>por usuario, por mes, cuando se factura mensualmente</p>
            </div>
            <h3><span>$</span>10</h3>
          </div>
          <div class="plan-box">
            <div>
              <h4>Equipo</h4>
              <p>
                Para personas que solo necesitan comenzar con las funciones
                básicas
              </p>
              <p>por usuario, por mes, cuando se factura mensualmente</p>
            </div>
            <h3><span>$</span>10</h3>
          </div>
          <div class="plan-box">
            <div>
              <h4>Pro</h4>
              <p>
                Para personas que solo necesitan comenzar con las funciones
                básicas
              </p>
              <p>por usuario, por mes, cuando se factura mensualmente</p>
            </div>
            <h3><span>$</span>10</h3>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="benefit-box">
            <h5>Beneficios</h5>
            <ul>
              <li>Acceso a la comunidad en Slack</li>
              <li>Acceso al equipo de soporte</li>
              <li>Licitación algorítmica</li>
              <li>Recopilación de palabras clave y ASIN</li>
            </ul>
            <h5>Características</h5>
            <ul>
              <li>Aislamiento de términos de búsqueda</li>
              <li>Análisis de ventas totales</li>
              <li>Rango de mejor vendedor</li>
              <li>Optimización de ubicación</li>
            </ul>
            <a href="javascript:;" class="btn btn-secondary w-100"
              >Empieza Gratis</a
            >
          </div>
        </div>
      </div>
      <!-- /Tipo de Plan -->

      <!-- Compara nuestros planes -->
      <div class="row">
        <div class="col-lg-12">
          <h2 class="main-title text-center">Compara nuestros planes</h2>
          <div class="table-responsive">
            <table class="table table-bordered table-price">
              <thead>
                <tr>
                  <td></td>
                  <td class="text-center self-cell">Autónomos & Freelance</td>
                  <td colspan="2" class="text-center business-cell">
                    Pequeñas empresas que necesitan contabilidad, facturación o
                    nómina
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr class="basic-cell">
                  <td></td>
                  <td class="text-center">Básico</td>
                  <td class="text-center">Equipo</td>
                  <td class="text-center">Pro</td>
                </tr>
                <tr>
                  <td>Separar negocios/personal</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Estimar pagos de impuestos</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Rastrear millaje deducible</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Descargar banca en línea</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Multi-dispositivo</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Crear facturas y estimaciones</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Gestionar IVA</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Gestionar facturas y pagos</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Monedas múltiples</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
                <tr>
                  <td>Crear presupuestos</td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/close-circle.svg" alt="icono" />
                  </td>
                  <td class="text-center">
                    <img src="@/assets/img/icon/check-circle.svg" alt="icono" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!-- /Compara nuestros planes -->
    </div>
  </section>
</template>
