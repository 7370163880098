<template>
  <div class="settings-page-head">
    <ul class="settings-pg-links">
      <li>
        <router-link to="/instructor/instructor-settings"
          ><i class="bx bx-edit"></i>Editar Perfil</router-link
        >
      </li>
      <li>
        <router-link to="/instructor/instructor-change-password"
          ><i class="bx bx-lock"></i>Cambiar Contraseña</router-link
        >
      </li>
      <li v-if="false">
        <router-link to="/instructor/instructor-setting-notifications"
          ><i class="bx bx-bell"></i>Notificaciones</router-link
        >
      </li>
      <li v-if="false">
        <router-link to="/instructor/instructor-setting-withdraw"
          ><i class="bx bx-wallet-alt"></i>Retirar</router-link
        >
      </li>
      <li v-if="false">
        <router-link to="/instructor/instructor-delete-account"
          ><i class="bx bx-error-alt"></i>Eliminar Cuenta</router-link
        >
      </li>
    </ul>
  </div>
</template>
