<template>
  <page-header v-if="auth.user"></page-header>
  <horizontal-header v-else></horizontal-header>
  <!-- <coursegrid-breadcrumb></coursegrid-breadcrumb> -->
  <!-- Course -->
  <section class="course-content academy-container">
    <div class="container">
      <div class="row">
        <h1 class="titulo-h1">Explora la Academia PapoNetwok</h1>
        <div class="col-lg-12">
          <p class="descripcion-texto">
            Descubre nuestra amplia gama de cursos en la Academia PapoNetwok.
            Explora y elige el que mejor se adapte a tu camino de aprendizaje.
            Usa el filtro para navegar fácilmente por nuestra diversa oferta de
            cursos.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <div class="row">
            <div class="filter-container">
              <!-- Campo de búsqueda -->
              <div class="search-box">
                <input
                  type="text"
                  v-model="nombre_curso"
                  placeholder="Buscar..."
                  class="form-control"
                />
              </div>

              <!-- Nivel de curso -->
              <div class="filter-group">
                <h5 class="titulo-h5">
                  <i class="fas fa-layer-group"></i> Nivel de curso
                </h5>
                <div
                  v-for="nivel in niveles_cursos"
                  :key="nivel"
                  class="filter-item"
                >
                  <input
                    type="checkbox"
                    :value="nivel"
                    v-model="selectedFilters.niveles_cursos"
                  />
                  <label>{{ nivel.nombre }}</label>
                </div>
              </div>

              <!-- Instructor -->
              <div class="filter-group">
                <h5 class="titulo-h5">
                  <i class="fas fa-user-tie"></i> Instructor
                </h5>
                <div
                  v-for="instructor in instructores"
                  :key="instructor"
                  class="filter-item"
                >
                  <input
                    type="checkbox"
                    :value="instructor"
                    v-model="selectedFilters.instructores"
                  />
                  <label>{{ instructor.nombre_completo }}</label>
                </div>
              </div>

              <!-- Categorías -->
              <div class="filter-group">
                <h5 class="titulo-h5">
                  <i class="fas fa-list"></i> Categorías
                </h5>
                <div
                  v-for="categoria in categorias"
                  :key="categoria"
                  class="filter-item"
                >
                  <input
                    type="checkbox"
                    :value="categoria"
                    v-model="selectedFilters.categorias"
                  />
                  <label>{{ categoria.nombre }}</label>
                </div>
              </div>

              <!-- Certificaciones -->
              <div class="filter-group">
                <h5 class="titulo-h5">
                  <i class="fas fa-certificate"></i> Certificaciones
                </h5>
                <div
                  v-for="certificacion in certificaciones"
                  :key="certificacion"
                  class="filter-item"
                >
                  <input
                    type="checkbox"
                    :value="certificacion"
                    v-model="selectedFilters.certificaciones"
                  />
                  <label>{{ certificacion.nombre }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <!-- <course-filter></course-filter> -->
          <div class="row">
            <div class="course-feature">
              <div class="row">
                <div
                  class="col-lg-6 col-md-6 d-flex"
                  v-for="curso in cursos"
                  :key="curso.id"
                >
                  <div class="course-box d-flex aos" data-aos="fade-up">
                    <div class="product">
                      <div class="product-img">
                        <router-link :to="`/course/course-details/${curso.id}`">
                          <img
                            class="img-fluid"
                            :alt="curso.nombre"
                            :src="curso.imagen"
                          />
                        </router-link>
                        <div class="price">
                          <h3>
                            Bs. {{ curso.precio }} <span>Bs. 500.00</span>
                          </h3>
                        </div>
                      </div>
                      <div class="product-content">
                        <div class="course-group d-flex">
                          <div class="course-group-img d-flex">
                            <img
                              :src="curso.foto_instructor"
                              alt=""
                              class="img-fluid"
                            />
                            <div class="course-name">
                              <h4>
                                {{ curso.instructor }}
                              </h4>
                              <p>Instructor</p>
                            </div>
                          </div>
                          <div
                            v-if="false"
                            class="course-share d-flex align-items-center justify-content-center"
                          >
                            <a href="javascript:void(0);">
                              <i class="fa-regular fa-heart"></i>
                            </a>
                          </div>
                        </div>
                        <h3 class="titulo-h3">
                          <router-link
                            :to="`/course/course-details/${curso.id}`"
                          >
                            {{ curso.nombre }}
                          </router-link>
                        </h3>
                        <div class="course-info d-flex align-items-center">
                          <div class="rating-img d-flex align-items-center">
                            <img src="@/assets/img/icon/icon-01.svg" alt="" />
                            <p>{{ curso.temas }}+ Lección</p>
                          </div>
                          <div class="course-view d-flex align-items-center">
                            <img src="@/assets/img/icon/icon-02.svg" alt="" />
                            <p>{{ curso.duracion }}</p>
                          </div>
                        </div>
                        <!--  <div class="btn-comprar d-flex align-items-center">
                          <router-link
                            to="/pages/checkout"
                            class="btn btn-primary btn-comprar"
                          >
                            COMPRAR
                          </router-link>
                        </div> -->
                        <div class="d-flex align-items-center w-100">
                          <router-link
                            to="/pages/checkout"
                            class="btn-comprar btn-block"
                          >
                            <span
                              ><i class="fas fa-shopping-cart"></i>
                              COMPRAR</span
                            >
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--  <pagination></pagination> -->
        </div>
        <!-- <course-sidebar></course-sidebar> -->
      </div>
    </div>
  </section>
  <!-- /Course -->

  <layouts1></layouts1>
</template>
<script>
import CursoService from "@/service/CursoService";
import AOS from "aos";
import { useAuth } from "@/stores"; // Importar el store de autenticación

export default {
  data() {
    return {
      cursos: [],
      // Datos simulados desde la base de datos
      niveles_cursos: [],
      instructores: [],
      categorias: [],
      certificaciones: [],

      // Valores seleccionados en los filtros
      selectedFilters: {
        niveles_cursos: [],
        instructores: [],
        categorias: [],
        certificaciones: [],
      },
      auth: useAuth(),

      // Campo de búsqueda
      nombre_curso: "",
    };
  },

  cursoService: null,

  mounted() {
    this.$nextTick(() => {
      this.obtenerCursos();
      this.datosNecessariosCurso();
      AOS.init(); // esto es para la animacion de los cursos
    });
  },

  created() {
    this.cursoService = new CursoService();
  },

  methods: {
    // Método para aplicar los filtros y enviar la consulta al servidor o filtrar localmente
    applyFilters() {
      const activeFilters = {
        ...this.selectedFilters,
        nombre_curso: this.nombre_curso,
      };
      console.log("Filtros aplicados:", activeFilters);
      // Aquí puedes emitir los filtros al padre o hacer una llamada al backend
    },
    obtenerCursos() {
      this.cursoService
        .obtenerCursos()
        .then((response) => {
          this.cursos = response.cursos;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    datosNecessariosCurso() {
      this.cursoService
        .datosNecessariosCurso()
        .then((response) => {
          this.niveles_cursos = response.niveles_cursos;
          this.instructores = response.instructores;
          this.categorias = response.categorias;
          this.certificaciones = response.certificaciones;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {
    // Escucha cambios en los filtros para aplicar automáticamente
    selectedFilters: {
      handler: "applyFilters",
      deep: true,
    },
    nombre_curso: "applyFilters",
  },
};
</script>
<style>
.filter-container {
  background: #171723;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #f7f1ee;
}

.search-box {
  margin-bottom: 20px;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-item {
  display: flex;
  width: 100%;
  height: 20px;
}
/* HAGO EL CHECK MAS GRANDE */
.filter-item input[type="checkbox"] {
  width: 20px;
  cursor: pointer;
  margin-right: 5px;
}

.filter-item label {
  color: #f7f1ee;
  font-family: "OCRAEXT";
  font-size: 14px;
}
.btn-comprar {
  display: block; /* Ancho completo */
  width: 100%; /* Responsivo: se adapta al contenedor */
  text-align: center; /* Centra el texto */
  padding: 15px 20px; /* Espaciado interno */
  font-size: 18px; /* Texto ligeramente más grande */
  font-weight: bold;
  text-transform: uppercase; /* Texto en mayúsculas */
  color: #171723; /* Texto blanco */
  background-color: #f7f1ee; /* Fondo inicial */
  border: 2px solid #171723; /* Borde */
  border-radius: 0; /* Bordes cuadrados */
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  position: relative; /* Para controlar el pseudo-elemento */
  overflow: hidden; /* Contener animaciones */
  z-index: 1; /* Prioridad para el texto */
}

/* Efecto de fondo animado */
.btn-comprar::before {
  content: "";
  position: absolute;
  top: -100%; /* Empieza fuera del botón */
  left: 0;
  width: 100%; /* Ancho completo */
  height: 100%;
  background: linear-gradient(90deg, #233269, #171723); /* Degradado */
  z-index: 0; /* Detrás del texto */
  transition: all 0.4s ease-in-out;
}

/* Animación al pasar el cursor */
.btn-comprar:hover::before {
  top: 0; /* Fondo visible */
}

/* Cambios en el texto y borde al pasar el cursor */
.btn-comprar:hover {
  color: #f7f1ee;
  border-color: #171723;
}

/* Efecto de clic */
.btn-comprar:active {
  transform: scale(0.98);
}

/* El texto encima del pseudo-elemento */
.btn-comprar span {
  position: relative;
  z-index: 1;
}

/* Responsivo para tamaños pequeños */
@media (max-width: 768px) {
  .btn-comprar {
    font-size: 16px; /* Texto más pequeño */
    padding: 12px 15px; /* Ajusta el espaciado */
  }
}

@media (max-width: 480px) {
  .btn-comprar {
    font-size: 14px; /* Texto más compacto */
    padding: 10px 12px;
  }
}
</style>
