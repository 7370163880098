<template>
  <div class="row">
    <!-- Cuadrícula de Cursos -->
    <div class="col-xxl-4 col-md-6 d-flex">
      <div class="course-box flex-fill">
        <div class="product">
          <div class="product-img">
            <router-link to="/course/course-details">
              <img class="img-fluid" alt="Img" src="@/assets/img/course/course-03.jpg" />
            </router-link>
            <div class="price combo">
              <h3>GRATIS</h3>
            </div>
          </div>
          <div class="product-content">
            <div class="course-group d-flex">
              <div class="course-group-img d-flex">
                <router-link to="/instructor/instructor-profile">
                  <img src="@/assets/img/user/user5.jpg" alt="Img" class="img-fluid" />
                </router-link>
                <div class="course-name">
                  <h4><router-link to="/instructor/instructor-profile">Jenny</router-link></h4>
                  <p>Instructor</p>
                </div>
              </div>
              <div class="course-share d-flex align-items-center justify-content-center">
                <a href="#"><i class="fa-regular fa-heart"></i></a>
              </div>
            </div>
            <h3 class="title instructor-text">
              <router-link to="/course/course-details">Sketch de A a Z (2022): Conviértete en un diseñador de aplicaciones</router-link>
            </h3>
            <div class="course-info d-flex align-items-center">
              <div class="rating-img d-flex align-items-center">
                <img src="@/assets/img/icon/icon-01.svg" alt="Img" />
                <p>10+ Lecciones</p>
              </div>
              <div class="course-view d-flex align-items-center">
                <img src="@/assets/img/icon/icon-02.svg" alt="Img" />
                <p>40hr 10min</p>
              </div>
            </div>
            <div class="rating mb-0">
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star filled me-1"></i>
              <i class="fas fa-star me-1"></i>
              <i class="fas fa-star me-1"></i>
              <span class="d-inline-block average-rating"><span>3.0</span> (18)</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Cuadrícula de Cursos -->

    <!-- Cuadrícula de Cursos -->

    <!-- /Cuadrícula de Cursos -->

    <!-- Cuadrícula de Cursos -->
   
    <!-- /Cuadrícula de Cursos -->
  </div>
</template>
