import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class CursoService {
  async obtenerCursos() {
    return await fetchWrapper.get(`${ruta}/cursos`);
  }

  async guardarCursoNuevo(curso) {
    return await fetchWrapper.post(`${ruta}/cursos/store`, curso);
  }

  async detalleCurso(id) {
    return await fetchWrapper.get(`${ruta}/cursos/${id}`);
  }
  async getInstructorAddCourse() {
    return await fetchWrapper.get(`${ruta}/cursos/get/instructors`);
  }
  async getCursoPorInstructor() {
    return await fetchWrapper.get(`${ruta}/instructor/get/cursos`);
  }

  async datosNecessariosCurso() {
    return await fetchWrapper.post(`${ruta}/cursos/datosNecessarios`);
  }
}
